import countryList from './country-list.json'

// Each country array has the following information:
// [
//    Country name,
//    iso2 code,
//    International dial code,
//    Order (if >1 country with same dial code),
//    Area codes
// ]

export default countryList.map((countryArray) => {
  const [name, iso2, dialCode, priority, areaCodes] = countryArray

  return {
    name,
    iso2,
    dialCode,
    priority,
    areaCodes
  }
})
