import './PhoneForm.scss'

import * as Yup from 'yup'

import PropTypes from 'prop-types'

import classnames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import countries from '../../lib/CountryList'

import translate from '../../i18next'

const ROOT_CLASS = 'change-phone'
const FORM_CLASS = `${ROOT_CLASS}-form`
const PHONE_FORM_GROUP_CLASS = `${FORM_CLASS}-phone`
const FORM_SUBMIT_SECTION_CLASS = `${FORM_CLASS}-submit-container`
const FORM_INPUT_ERROR_MESSAGE_CLASS = `${FORM_CLASS}-input-error-message`
const FORM_INPUT_ERROR_CLASS = `${FORM_CLASS}-input-error`
const SUBMIT_BUTTON_CLASS = `${FORM_SUBMIT_SECTION_CLASS}-submit-button`
const DARK_GRAY_BORDERED_SELECT_CLASS = 'dealme-dark-gray-bordered-select'

const PhoneForm = ({ onSubmit, isLoading, phone, countryCode }) => {
  const phoneCountryLabel = useMemo(() => {
    if (!countryCode) return '+1 US'
    const country = countries.filter((c) => c.iso2 === countryCode)
    return country[0] ? `+${country[0].dialCode} ${countryCode.toUpperCase()} ` : ''
  }, [countries, countryCode])

  const INITIAL_FORM_VALUES = {
    phoneCountry: { value: countryCode, label: phoneCountryLabel },
    mobilePhone: phone
  }
  const [phoneCountryIso2, setPhoneCountryIso2] = useState(countryCode)

  const formValidationSchema = useMemo(() => (
    Yup.object().shape({
      phoneCountry: Yup.object()
        .required('Required'),
      mobilePhone: Yup.string()
        .required('Required') // TODO: get translate from designer
        .phone(phoneCountryIso2, true, 'Phone number is invalid')
    })
  ), [Yup, phoneCountryIso2])

  const phoneCountryDropdownOptions = useMemo(() => countries.map((country) => ({
    value: country.iso2,
    label: `+${country.dialCode} ${country.iso2.toUpperCase()}`
  })), [countries])

  const handlePhoneCountryChange = useCallback(({ setFieldValue }) => (selectedObject) => {
    setFieldValue('phoneCountry', selectedObject)
    setPhoneCountryIso2(selectedObject.value)
  }, [])

  const handlePhoneCountryBlur = useCallback(({ setFieldTouched }) => () => {
    setFieldTouched('phoneCountry', true)
  }, [])

  return (
    <Container className={ROOT_CLASS}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={formValidationSchema}
        initialValues={INITIAL_FORM_VALUES}
      >
        {({ values, errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, handleSubmit }) => (
          <Form className={FORM_CLASS} onSubmit={handleSubmit}>
            <Form.Label>* {translate('components.Signup.MOBILE_PHONE_NUMBER_LABEL')}</Form.Label>
            <Form.Group as={Row} className={PHONE_FORM_GROUP_CLASS}>
              <Form.Group>
                <Select
                  isMulti={false}
                  classNamePrefix={DARK_GRAY_BORDERED_SELECT_CLASS}
                  value={values.phoneCountry}
                  options={phoneCountryDropdownOptions}
                  placeholder={translate('components.Signup.PHONE_COUNTRY_SELECT_PLACEHOLDER')}
                  onBlur={handlePhoneCountryBlur({ setFieldTouched })}
                  onChange={handlePhoneCountryChange({ setFieldValue })}
                />
                <Col>
                  <Form.Control
                    required
                    type="text"
                    name="mobilePhone"
                    autoComplete="tel"
                    value={values.mobilePhone}
                    placeholder={translate('components.Signup.MOBILE_PHONE_NUMBER_INPUT_PLACEHOLDER')}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.mobilePhone && touched.mobilePhone })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="mobilePhone" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Col>
              </Form.Group>
            </Form.Group>
            <Container className={FORM_SUBMIT_SECTION_CLASS}>
              <Button
                type="submit"
                disabled={isLoading || !isValid}
                className={SUBMIT_BUTTON_CLASS}
              >
                {translate('components.Common.SAVE')}
              </Button>
            </Container>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

PhoneForm.propTypes = {
  isLoading: PropTypes.bool,
  phone: PropTypes.string,
  countryCode: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
}

PhoneForm.defaultProps = {
  phone: '',
  countryCode: 'us',
  isLoading: false
}

export default PhoneForm
