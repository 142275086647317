/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import './index.scss'

const ViewAsManager = ({ influencers, setInfluencer, influencer, isAdmin }) => {
  return (
    <div className="form-group col-12 col-md-6 view-as-manager">
      <label className="form-label">
        <span className="text-white">View as</span>
        <select
          name="firstName"
          type="text"
          className="form-control"
          placeholder="Search any user"
          value={influencer?.submission_form_slug ?? ''}
          onChange={(e) => setInfluencer(influencers.find((influencer) => influencer.submission_form_slug === e.target.value))}
        >
          {isAdmin && (
            <option value="" key="all">all</option>
          )}
          {influencers?.map((influencer) => (
            <option className="search-result-item" key={influencer.submission_form_slug} value={influencer.submission_form_slug}>
              {influencer.firstname} {influencer.lastname}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}

export default ViewAsManager
