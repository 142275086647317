/* eslint-disable react/prop-types */
import Container from 'react-bootstrap/Container'
import PropTypes from 'prop-types'
import { markNotificationasReaded } from '../../helpers/markNotificationasReaded'
import { markNotificationasUnReaded } from '../../helpers/markNotificationasUnReaded'
import { deleteNotification } from '../../helpers/deleteNotification'
import NotificationCard from './NotificationCard'
import './index.scss'
import useUser from '../../hooks/useUser'
import { ReactComponent as NotMessages } from '../../assets/icons/messages.svg'

export default function Notifications({ state, readNotifications, unreadNotifications, setReadNotifications, setUnreadNotifications }) {
  const ROOT_CLASS = 'dealme-notifications'
  const UNREAD_CLASS = `${ROOT_CLASS}-unread`
  const EMPTY_AREA_CLASS = `${ROOT_CLASS}-empty-area`
  const READ_CLASS = `${ROOT_CLASS}-read`
  const EMPTY_BUTTON_CLASS = `${ROOT_CLASS}-empty-button`
  const BUTTON_CLASS = `${ROOT_CLASS}-button`
  const CLOSE_BUTTON_CLASS = `${ROOT_CLASS}-close-button`
  const TITLE_AND_CLOSE_CONTAINER_CLASS = `${ROOT_CLASS}-title-and-close-container`
  const TITLE_AND_BUTTON_CONTAINER_CLASS = `${ROOT_CLASS}-title-and-button-container`
  const UNREAD_NOTIFICATIONS_CONTAINER_CLASS = `${ROOT_CLASS}-unread-notifications-container`
  const READ_NOTIFICATIONS_CONTAINER_CLASS = `${ROOT_CLASS}-read-notifications-container`
  const { userData } = useUser()

  const readIsEmpty = readNotifications.length === 0
  const unreadIsEmpty = unreadNotifications.length === 0

  const markAsReaded = (token, id) => {
    markNotificationasReaded(token, id)
    setReadNotifications([...readNotifications, unreadNotifications.find((notification) => notification.id === id)])
    setUnreadNotifications(unreadNotifications.filter((notification) => notification.id !== id))
  }

  const markAsUnReaded = (token, id) => {
    markNotificationasUnReaded(token, id)
    setReadNotifications(readNotifications.filter((notification) => notification.id !== id))
    setUnreadNotifications([...unreadNotifications, readNotifications.find((notification) => notification.id === id)])
  }

  const onDelete = (token, id) => {
    deleteNotification(token, id)
    setReadNotifications(readNotifications.filter((notification) => notification.id !== id))
    setUnreadNotifications(unreadNotifications.filter((notification) => notification.id !== id))
  }

  return (
    <Container className={ROOT_CLASS}>
      <div className={TITLE_AND_CLOSE_CONTAINER_CLASS}>
        <h1>Notifications</h1>
        <button type="button" className={CLOSE_BUTTON_CLASS} onClick={() => state(false)}>
          <img src="/close-button.png" alt="" />
        </button>
      </div>
      {readIsEmpty && unreadIsEmpty
        ? (
          <div className={TITLE_AND_BUTTON_CONTAINER_CLASS}>
            <div className="ml-5">
              <NotMessages />
              <h2 className="px-5 d-block">You do not have any notifications to review</h2>
            </div>
          </div>
        )
        : (
          <>
            <div className={unreadIsEmpty ? EMPTY_AREA_CLASS : UNREAD_CLASS}>
              <div className={TITLE_AND_BUTTON_CONTAINER_CLASS}>
                <h2>Unread</h2>
                <button
                  type="button"
                  onClick={function () {
                    unreadNotifications.map(({ id }) => markNotificationasReaded(userData.token, id))
                    setReadNotifications(readNotifications.concat(unreadNotifications))
                    setUnreadNotifications([])
                  }}
                  className={unreadIsEmpty ? EMPTY_BUTTON_CLASS : BUTTON_CLASS}
                >Mark all as read
                </button>
              </div>
            </div>
            <div className={UNREAD_NOTIFICATIONS_CONTAINER_CLASS}>
              {unreadNotifications?.map((item) => {
                return (
                  <NotificationCard
                    key={item.id}
                    text={item.text}
                    image={item.from_user.image}
                    time={item.created_at}
                    read={false}
                    token={userData.token}
                    id={item.id}
                    markAsReaded={markAsReaded}
                    markAsUnReaded={markAsUnReaded}
                    onDelete={onDelete}
                  />
                )
              })}
            </div>
            <div className={unreadIsEmpty ? EMPTY_AREA_CLASS : READ_CLASS}>
              <div className={TITLE_AND_BUTTON_CONTAINER_CLASS}>
                <h2>Read</h2>
                <button
                  type="button"
                  onClick={function () {
                    readNotifications.map(({ id }) => markNotificationasUnReaded(userData.token, id))
                    setUnreadNotifications(readNotifications.concat(unreadNotifications))
                    setReadNotifications([])
                  }}
                  className={readIsEmpty ? EMPTY_BUTTON_CLASS : BUTTON_CLASS}
                >Mark all as unread
                </button>
              </div>

            </div>
            <div className={READ_NOTIFICATIONS_CONTAINER_CLASS}>
              {readNotifications?.map((item) => {
                return (
                  <NotificationCard
                    key={item?.id}
                    text={item?.text}
                    image={item?.from_user.image}
                    time={item?.created_at}
                    read
                    token={userData?.token}
                    id={item?.id}
                    markAsReaded={markAsReaded}
                    markAsUnReaded={markAsUnReaded}
                    onDelete={onDelete}
                  />
                )
              })}
            </div>
          </>
        )}
    </Container>
  )
}

Notifications.propTypes = {
  state: PropTypes.func.isRequired
}
