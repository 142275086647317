import './index.scss'

// import { ReactComponent as SpinerImage } from '../../../assets/icons/small-logo.svg'

const SpinnerCustom = () => {
  return (
    <div className="dealme-overlay">
      {/* <SpinerImage className="spinner" /> */}
      <div className="spinner"><img src="/gradient-loader.png" alt="X" /></div>
    </div>
  )
}

export default SpinnerCustom
