import './index.scss'

import { withRouter, useParams } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import Container from 'react-bootstrap/Container'
import translate from '../../i18next'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'

import OTPForm from '../OTPForm'
import EmailForm from './EmailForm'
import NewPasswordForm from './NewPasswordForm'

import { changeUserPassword, requestOneTimePasscode } from '../../store/actions'

const ROOT_CLASS = 'dealme-forgot-password'
const LOGO_CLASS = `${ROOT_CLASS}-logo`
const CAPTION_CONTAINER_CLASS = `${ROOT_CLASS}-caption-container`

const STEPS = ['EMAIL_REQUEST', 'OTP_REQUEST', 'CHANGE_PASSWORD']
const LOGIN_ERROR_TOAST_AUTOCLOSE = 10 * 1000 // 10 seconds

const ForgotPassword = withRouter(({ history, onPasswordChanged, onRequestOnTimePasscode }) => {
  const { forgotcode } = useParams()
  const [wizardStep, setWizardStep] = useState(forgotcode ? STEPS[1] : STEPS[0])
  const [mail, setMail] = useState(null)
  const [code, setCode] = useState(null)

  const handleOnHomeIconClick = useCallback(() => {
    history.push('/')
  }, [history])

  const handleOnEmailFormSubmit = useCallback(async ({ email }) => {
    // console.log('handleOnEmailFormSubmit', email)
    setMail(email)
    onRequestOnTimePasscode({ email })
      .then(() => {
        setWizardStep(STEPS[1])
      })
      .catch(() => {
        toast.error(translate('components.ForgotPassword.ERROR_REQUESTING_OTP'), { autoClose: LOGIN_ERROR_TOAST_AUTOCLOSE })
      })
  }, [onRequestOnTimePasscode])

  const handleOnPasswordChanged = useCallback(async (values) => {
    onPasswordChanged({ password: values.password, code })
      .then(() => {
        toast.success(translate('components.ForgotPassword.PASSWORD_CHANGED_FEEDBACK_CAPTION'))
        history.push(mail ? `/signin/${mail}` : '/signin')
      })
      .catch(() => {
        toast.error(translate('components.ForgotPassword.PASSWORD_CHANGED_FEEDBACK_ERROR'))
      })

    // await onPasswordChanged()
    // await toast.success(translate('components.ForgotPassword.PASSWORD_CHANGED_FEEDBACK_CAPTION'))
  }, [history, toast, onPasswordChanged, code, mail])

  const handleJumpToOTPButtonClick = useCallback(() => {
    setWizardStep(STEPS[1])
  }, [wizardStep])

  const handleOnOTPFormVerified = useCallback((code) => {
    setCode(code || forgotcode)
    setWizardStep(STEPS[2])
  }, [])

  const renderEmailForm = useCallback(() => {
    return (
      <EmailForm
        onJumpToOTP={handleJumpToOTPButtonClick}
        onSubmit={handleOnEmailFormSubmit}
      />
    )
  }, [handleOnEmailFormSubmit, handleJumpToOTPButtonClick])

  const renderOTPForm = useCallback(() => {
    return (
      <OTPForm onVerified={handleOnOTPFormVerified} resendCode={() => handleOnEmailFormSubmit({ email: mail })} />
    )
  }, [handleOnOTPFormVerified, mail])

  const renderNewPasswordForm = useCallback(() => {
    return (
      <NewPasswordForm
        onPasswordChanged={handleOnPasswordChanged}
      />
    )
  }, [handleOnPasswordChanged])

  const renderWizardStep = useCallback(() => {
    switch (wizardStep) {
      case STEPS[0]:
        return renderEmailForm()
      case STEPS[1]:
        return renderOTPForm()
      case STEPS[2]:
        return renderNewPasswordForm()
      default:
        return renderEmailForm()
    }
  }, [wizardStep, renderEmailForm, renderOTPForm])

  return (
    <Container className={ROOT_CLASS}>
      <Logo
        className={LOGO_CLASS}
        onClick={handleOnHomeIconClick}
      />
      <Container className={CAPTION_CONTAINER_CLASS}>
        <p>{translate('components.ForgotPassword.FORGOT_CAPTION')}</p>
      </Container>
      {renderWizardStep()}
    </Container>
  )
})

ForgotPassword.propTypes = {
  onPasswordChanged: PropTypes.func.isRequired,
  onRequestOnTimePasscode: PropTypes.func.isRequired
}

export const mapStateToProps = (stateProps, ownProps) => {
  return {
    ...stateProps,
    ...ownProps
  }
}

export const mapDispatchToProps = { changeUserPassword, requestOneTimePasscode }

const mergeProps = (_, { changeUserPassword, requestOneTimePasscode }, ownProps) => ({
  ...ownProps,
  onRequestOnTimePasscode: (payload) => requestOneTimePasscode(payload),
  onPasswordChanged: (payload) => changeUserPassword(payload)
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ForgotPassword)
