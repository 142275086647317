import './index.scss'

import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import SideBarMenu from './index'
import NavBarMenu from '../NavBarMenu'

const ROOT_CLASS = 'dealme-sidebar'
const SIDEBAR_MENU_CHILDREN_CONTAINER_CLASS = `${ROOT_CLASS}-children-container`

const withSideBarMenu = ({ defaultCollapsed }) => (children) => {
  return () => {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
    const handleOnSideBarMenuCollapse = useCallback(() => {
      setIsCollapsed(!isCollapsed)
    }, [isCollapsed])

    return (
      <div className={ROOT_CLASS}>
        <SideBarMenu
          isCollapsed={isCollapsed}
          onCollapse={handleOnSideBarMenuCollapse}
        />
        <NavBarMenu />
        <div className={`${SIDEBAR_MENU_CHILDREN_CONTAINER_CLASS} ${isCollapsed ? 'collapsed' : ''}`}>
          {children()}
        </div>
      </div>
    )
  }
}

withSideBarMenu.prototypes = {
  children: PropTypes.func,
  isCollapsed: PropTypes.bool
}

export default withSideBarMenu
