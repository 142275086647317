import { useContext } from 'react'
import { UserContext } from '../context/user'

const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser hook must be used under the user context')
  }
  return context
}

export default useUser
