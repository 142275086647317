import { authRoutes } from '../../api/routes'
import ApiClient from '../../api/api_client'

import {
  SIGN_IN,
  SIGNED_IN,
  SIGN_IN_FAILED,
  CHANGE_PASSWORD,
  PASSWORD_CHANGED,
  PASSWORD_CHANGE_FAILED,
  REQUEST_ONE_TIME_PASSCODE,
  ONE_TIME_PASSCODE_REQUESTED,
  ONE_TIME_PASSCODE_REQUEST_FAILED,
  VERIFY_ONE_TIME_PASSCODE,
  ONE_TIME_PASSCODE_VERIFIED,
  ONE_TIME_PASSCODE_VERIFICATION_FAILED,
  INFLUENCER_SIGN_UP,
  INFLUENCER_SIGN_UP_FAILED,
} from './user.types'

export const signIn = (payload) => {
  return (dispatch) => {
    dispatch({ type: SIGN_IN })

    return new Promise((resolve, reject) => {
      ApiClient.post(authRoutes.USER_SIGN_IN, payload)
        .then(({ data }) => {
          dispatch({ type: SIGNED_IN, data })
          return resolve(data)
        })
        .catch((error) => {
          dispatch({ type: SIGN_IN_FAILED })
          return reject(error)
        })
    })
  }
}

export const signUp = (payload) => {
  // console.log('signUp payload', payload)
  return (dispatch) => {
    dispatch({ type: INFLUENCER_SIGN_UP })

    return new Promise((resolve, reject) => {
      ApiClient.post(authRoutes.INFLUENCER_SIGN_UP, payload)
        .then(({ data }) => {
          dispatch({ type: INFLUENCER_SIGN_UP, data })
          return resolve(data)
        })
        .catch((error) => {
          dispatch({ type: INFLUENCER_SIGN_UP_FAILED })
          return reject(error)
        })
    })
  }
}

export const changeUserPassword = (payload) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_PASSWORD })
    // console.log('changeUserPassword payload', payload)
    return new Promise((resolve, reject) => {
      ApiClient.put(authRoutes.EMAIL_PASSWORD_RECOVERY_CHANGE, payload)
        .then(({ data }) => {
          // console.log('changeUserPassword response', data)
          dispatch({ type: PASSWORD_CHANGED, data })
          return resolve(data)
        })
        .catch((error) => {
          dispatch({ type: PASSWORD_CHANGE_FAILED })
          return reject(error)
        })
    })
  }
}

export const requestOneTimePasscode = (payload) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_ONE_TIME_PASSCODE })

    return new Promise((resolve, reject) => {
      ApiClient.post(authRoutes.EMAIL_PASSWORD_RECOVERY, payload)
        .then(({ data }) => {
          // console.log('email recovery', data)
          dispatch({ type: ONE_TIME_PASSCODE_REQUESTED, data })
          return resolve(data)
        })
        .catch((error) => {
          dispatch({ type: ONE_TIME_PASSCODE_REQUEST_FAILED })
          return reject(error)
        })
    })
  }
}

export const verifyOneTimePasscode = (payload) => {
  return (dispatch) => {
    dispatch({ type: VERIFY_ONE_TIME_PASSCODE })
    // console.log('payload', payload)

    return new Promise((resolve, reject) => {
      ApiClient.get(`${authRoutes.EMAIL_PASSWORD_RECOVERY_VERIFICATION}/${payload.code}`, payload)
        .then(({ data }) => {
          // console.log('email recovery validation', data)
          dispatch({ type: ONE_TIME_PASSCODE_VERIFIED, data })
          return resolve(data)
        })
        .catch((error) => {
          dispatch({ type: ONE_TIME_PASSCODE_VERIFICATION_FAILED })
          return reject(error)
        })
    })
  }
}

export const verifyUserValidationCode = (payload) => {
  return (dispatch) => {
    dispatch({ type: VERIFY_ONE_TIME_PASSCODE })

    return new Promise((resolve, reject) => {
      ApiClient.post(authRoutes.EMAIL_VERIFICATION, payload)
        .then(({ data }) => {
          dispatch({ type: ONE_TIME_PASSCODE_VERIFIED, data }) // RECIBO email
          return resolve(data)
        })
        .catch((error) => {
          dispatch({ type: ONE_TIME_PASSCODE_VERIFICATION_FAILED })
          return reject(error)
        })
    })
  }
}
