import { apiCall } from './api_client'
import { USER_IMAGE_UPLOAD, USER_UPDATE, USER_DELETE, EMAIL_PASSWORD_RECOVERY_CHANGE, USER_CHANGES_VERIFICATION } from './auth.routes'

export const uploadImage = (data) => apiCall(USER_IMAGE_UPLOAD, {
  method: 'POST',
  immediately: true,
  data,
})

export const updateData = (data) => {
  // apiCall(`${USER_UPDATE}/${data.id}`, {
  return apiCall(USER_UPDATE, {
    method: 'PUT',
    immediately: true,
    data,
  })
}

export const updatePassword = (data) => {
  return apiCall(EMAIL_PASSWORD_RECOVERY_CHANGE, {
    method: 'PUT',
    immediately: true,
    data,
  })
}

export const validateChanges = (code) => (
  apiCall(USER_CHANGES_VERIFICATION, { method: 'PUT', immediately: true, data: { code } })
)

export const deleteUser = () => apiCall(USER_DELETE, {
  method: 'DELETE',
  immediately: true
})
