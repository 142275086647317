import Home from './components/Home'
import AccountSetting from './components/AccountSetting'
import { GetStarted } from './components/GetStarted'
import AdminUsers from './components/AdminUsers'

const routes = [
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/account-settings',
    component: AccountSetting
  },
  {
    path: '/admin-users',
    component: AdminUsers
  },
  {
    path: '/getstarted',
    component: GetStarted
  }
]

export default routes
