import './index.scss'

import { withRouter, useParams } from 'react-router-dom'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import 'yup-phone'

import classnames from 'classnames'
import { toast } from 'react-toastify'
import { Formik, ErrorMessage } from 'formik'
import Select from 'react-select'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import countries from '../../lib/CountryList'

import translate from '../../i18next'
import { signUp } from '../../store/actions'
import { isLoadingSelector } from '../../store/selectors/userSelector'
import { acceptInvitation, getInvitation } from '../../api/manager'

import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg'
import { ReactComponent as DolarLogo } from '../../assets/icons/dolar_logo_transparent.svg'
import ImageDefault from '../../assets/Signup/cover.jpg'
import { acceptInvitationAdmin, getInvitationAdmin } from '../../api/admin'
import SpinnerCustom from '../common/SpinnerCustom'
import storage from '../../helpers/storage'
import { USER_STORAGE_KEY } from '../../store/actions/user.types'

const ROOT_CLASS = 'dealme-signup'
const LOGO_CLASS = `${ROOT_CLASS}-logo`
const LEFT_SIDE_CONTAINER_CLASS = `${ROOT_CLASS}-left-side-container`
const LEFT_SIDE_IMAGE_CLASS = `${ROOT_CLASS}-left-side-image`
const RIGHT_SIDE_CONTAINER_CLASS = `${ROOT_CLASS}-right-side-container`
const RIGHT_SIDE_INNER_CONTAINER_CLASS = `${ROOT_CLASS}-right-side-inner-container`
const CAPTION_CONTAINER_CLASS = `${ROOT_CLASS}-right-side-caption-container`
const FORM_CONTAINER_CLASS = `${ROOT_CLASS}-form-container`
const LOGO_TRANSPARENT_CLASS = `${ROOT_CLASS}-logo-transparent`
const SIGNUP_FORM_CLASS = `${ROOT_CLASS}-form`
const PHONE_FORM_GROUP_CLASS = `${SIGNUP_FORM_CLASS}-phone-form-group`
const SIGNUP_FORM_SUBMIT_SECTION_CLASS = `${ROOT_CLASS}-form-submit-container`
const FORM_INPUT_ERROR_MESSAGE_CLASS = `${SIGNUP_FORM_CLASS}-input-error-message`
const FORM_INPUT_ERROR_CLASS = `${SIGNUP_FORM_CLASS}-input-error`
const DARK_GRAY_BORDERED_SELECT_CLASS = 'dealme-dark-gray-bordered-select'

const DEFAULT_PHONE_COUNTRY_VALUE = 'us'
const DEFAULT_PHONE_COUNTRY_LABEL = '+1 US'

const SIGN_UP_ERROR_TOAST_AUTOCLOSE = 10 * 1000 // 10 seconds

const INITIAL_FORM_VALUES = {
  firstname: '',
  lastname: '',
  email: '',
  phoneCountry: { value: DEFAULT_PHONE_COUNTRY_VALUE, label: DEFAULT_PHONE_COUNTRY_LABEL },
  mobilePhone: '',
  password: '',
  confirmPassword: '',
  company: '-'
}

const SignUp = withRouter(({ history, onSignUp, isLoading }) => {
  // const [isLoading] = useState(false)
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [phoneCountryIso2, setPhoneCountryIso2] = useState(DEFAULT_PHONE_COUNTRY_VALUE)
  const [invitationData, setInvitationData] = useState()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const { idInvitation, tokenAdmin } = useParams()

  const formValidationSchema = useMemo(() => (
    idInvitation || tokenAdmin
      ? Yup.object().shape({
        firstname: Yup.string()
          .required('Required'), // TODO: get translate from designer
        lastname: Yup.string()
          .required('Required'), // TODO: get translate from designer
        phoneCountry: Yup.object()
          .required('Required'),
        mobilePhone: Yup.string()
          .required('Required') // TODO: get translate from designer
          .phone(phoneCountryIso2, true, 'Phone number is invalid'), // TODO
        password: Yup.string()
          .required('Required'), // TODO: get translate from designer
        confirmPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('password')], 'Passwords must match') // TODO: get translate from designer
      })
      : Yup.object().shape({
        firstname: Yup.string()
          .required('Required'), // TODO: get translate from designer
        lastname: Yup.string()
          .required('Required'), // TODO: get translate from designer
        email: Yup.string()
          .email('Invalid email') // TODO: get translate from designer
          .required('Required'), // TODO: get translate from designer
        phoneCountry: Yup.object()
          .required('Required'),
        mobilePhone: Yup.string()
          .required('Required') // TODO: get translate from designer
          .phone(phoneCountryIso2, true, 'Phone number is invalid'), // TODO
        password: Yup.string()
          .required('Required'), // TODO: get translate from designer
        confirmPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('password')], 'Passwords must match') // TODO: get translate from designer
      })
  ), [Yup, phoneCountryIso2, idInvitation, tokenAdmin])

  const phoneCountryDropdownOptions = useMemo(() => countries.map((country) => ({
    value: country.iso2,
    label: `+${country.dialCode} ${country.iso2.toUpperCase()}`
  })), [countries])

  const passwordFieldType = useMemo(() => {
    return isPasswordVisible ? 'text' : 'password'
  }, [isPasswordVisible])

  const handleOnHomeIconClick = useCallback(() => {
    history.push('/')
  }, [history])

  const handlePasswordVisibleIconClick = useCallback(() => {
    setPasswordVisible(!isPasswordVisible)
  }, [isPasswordVisible])

  const handleOnFormSubmit = useCallback(async ({ firstname, lastname, email, phoneCountry, mobilePhone, password, company }) => {
    setIsLoadingData(true)
    await storage.asyncSet(USER_STORAGE_KEY, { password, email })
    if (invitationData) {
      const result = invitationData.manager
        ? acceptInvitation({
          invitation_token: invitationData.invitation_token,
          firstname,
          lastname,
          country_code: phoneCountry.value,
          phone: mobilePhone,
          password,
          company
        })
        : acceptInvitationAdmin({
          token: invitationData.invitation_token,
          firstname,
          lastname,
          country_code: phoneCountry.value,
          phone: mobilePhone,
          password
        })
      result.then(() => {
        history.push(`/signin/${email}`)
        toast.success(translate('components.Signup.SIGNUP_SUCCESS'), { autoClose: SIGN_UP_ERROR_TOAST_AUTOCLOSE })
      })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            toast.error(error.response.data.message, { autoClose: SIGN_UP_ERROR_TOAST_AUTOCLOSE })
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
            console.error('error signup', error)
          }
        })
        .finally(() => setIsLoadingData(false))
    } else {
      onSignUp({ firstname, lastname, email, phoneCountry, mobilePhone, password })
        .then(() => history.push('/validate-user'))
        .catch((error) => {
          if (error.response && error.response.data.message) {
            toast.error(error.response.data.message, { autoClose: SIGN_UP_ERROR_TOAST_AUTOCLOSE })
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
            console.error('error signup', error)
          }
        })
        .finally(() => setIsLoadingData(false))
    }
  }, [history, onSignUp, toast, invitationData])

  const handleOnSignInButtonClick = useCallback(() => {
    history.push('/signin')
  }, [history])

  const handlePhoneCountryChange = useCallback(({ setFieldValue }) => (selectedObject) => {
    setFieldValue('phoneCountry', selectedObject)
    setPhoneCountryIso2(selectedObject.value)
  }, [])

  const handlePhoneCountryBlur = useCallback(({ setFieldTouched }) => () => {
    setFieldTouched('phoneCountry', true)
  }, [])

  const renderPasswordVisibilityIcon = useCallback(() => {
    return isPasswordVisible ? (
      <EyeSlashIcon onClick={handlePasswordVisibleIconClick} />
    ) : (
      <EyeIcon onClick={handlePasswordVisibleIconClick} />
    )
  }, [isPasswordVisible, handlePasswordVisibleIconClick])

  useEffect(() => {
    // console.log('useffect idInvitation', idInvitation)
    if (idInvitation && idInvitation.length > 9) {
      setIsLoadingData(true)
      getInvitation(idInvitation)
        .then(({ data }) => {
          // console.log('Invitation data', data)
          if (data.manager_exists) {
            return acceptInvitation({ invitation_token: data.invitation_token })
          }
          setInvitationData(data)
        })
        .then(async (data) => {
          // console.log('data confirmation invitation', data)
          if (data && data.data) {
            const user = await storage.asyncGet(USER_STORAGE_KEY)
            if (user && user.token && data.data.manager && user.email === data.data.manager.email) {
              history.push('/home')
            } else {
              history.push(`/signin/${data.data.manager.email}`)
            }
            toast.success(translate('components.Common.INVITATION_ACCEPTED'), { autoClose: SIGN_UP_ERROR_TOAST_AUTOCLOSE, toastId: 'INVITATION_ACCEPTED' })
          }
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
            console.error('error read invitation', error)
          }
          history.push('/home')
        })
        .finally(() => setIsLoadingData(false))
    }
  }, [idInvitation])

  useEffect(() => {
    if (tokenAdmin && tokenAdmin.length > 9) {
      setIsLoadingData(true)
      getInvitationAdmin(tokenAdmin)
        .then(({ data }) => {
          // console.log('data', data)
          setInvitationData(data)
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
            console.error('error read invitation', error)
          }
        })
        .finally(() => setIsLoadingData(false))
    }
  }, [tokenAdmin])

  return (
    <Container fluid className={ROOT_CLASS}>
      {isLoadingData && <SpinnerCustom />}
      <Row>
        <Col md={6} className={LEFT_SIDE_CONTAINER_CLASS}>
          <img
            className={LEFT_SIDE_IMAGE_CLASS}
            // src={invitationData && invitationData.influencer && invitationData.influencer.image ? `${BACKEND_URL}/${invitationData.influencer.image}` : ImageDefault}
            src={invitationData && invitationData.influencer && invitationData.influencer.image ? invitationData.influencer.image : ImageDefault}
            alt=""
          />
          <DolarLogo className={LOGO_TRANSPARENT_CLASS} />
        </Col>
        <Col sm={12} md="auto" className={RIGHT_SIDE_CONTAINER_CLASS}>
          <Container className={RIGHT_SIDE_INNER_CONTAINER_CLASS}>
            <Logo
              className={LOGO_CLASS}
              onClick={handleOnHomeIconClick}
            />
            <Container className={`${CAPTION_CONTAINER_CLASS} pl-md-0`}>
              <p>
                {invitationData && invitationData.manager ? 'Manager ' : invitationData && invitationData.to_user && 'Admin '}
                {translate('components.Signup.SIGNUP')}
              </p>
              <p>{translate('components.Signup.SIGNUP_CAPTION')}</p>
              <p>* {translate('components.Signup.REQUIRED_FIELDS')}</p>
            </Container>
            <Container className={FORM_CONTAINER_CLASS}>
              <Formik
                enableReinitialize
                onSubmit={handleOnFormSubmit}
                validateOnBlur={false}
                initialValues={invitationData ? {
                  ...INITIAL_FORM_VALUES,
                  firstname: (invitationData.manager && invitationData.manager.firstname) || invitationData.to_user.firstname,
                  lastname: (invitationData.manager && invitationData.manager.lastname) || invitationData.to_user.lastname,
                  email: (invitationData.manager && invitationData.manager.email) || invitationData.to_user.email,
                  company: (invitationData.manager && invitationData.manager.company) || '',
                } : INITIAL_FORM_VALUES}
                validationSchema={formValidationSchema}
              >
                {({ values, errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, handleSubmit }) => (
                  <Form noValidate className={SIGNUP_FORM_CLASS} onSubmit={handleSubmit}>
                    <Row>
                      <Form.Group as={Col} sm={12} md={6}>
                        <Form.Label>* {translate('components.Signup.FIRST_NAME_LABEL')}</Form.Label>
                        <Form.Control
                          required
                          autoFocus
                          type="text"
                          name="firstname"
                          autoComplete="given-name"
                          value={values.firstname}
                          placeholder={translate('components.Signup.FIRST_NAME_INPUT_PLACEHOLDER')}
                          className={
                            classnames({ [FORM_INPUT_ERROR_CLASS]: errors.firstname && touched.firstname })
                          }
                          disabled={isLoading}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="firstname" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                      </Form.Group>
                      <Form.Group as={Col} sm={12} md={6}>
                        <Form.Label>* {translate('components.Signup.LAST_NAME_LABEL')}</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="lastname"
                          autoComplete="family-name"
                          value={values.lastname}
                          placeholder={translate('components.Signup.LAST_NAME_INPUT_PLACEHOLDER')}
                          className={
                            classnames({ [FORM_INPUT_ERROR_CLASS]: errors.lastname && touched.lastname })
                          }
                          disabled={isLoading}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="lastname" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                      </Form.Group>
                    </Row>
                    <Row>
                      {(invitationData
                        ? invitationData && invitationData.manager && (
                          <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>* {translate('components.Signup.COMPANY_LABEL')}</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="company"
                              autoComplete="company"
                              value={values.company}
                              placeholder={translate('components.Signup.COMPANY_INPUT_PLACEHOLDER')}
                              className={
                                classnames({ [FORM_INPUT_ERROR_CLASS]: errors.company && touched.company })
                              }
                              disabled={isLoading}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="company" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                          </Form.Group>
                        )
                        : (
                          <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>* {translate('components.Signup.EMAIL_LABEL')}</Form.Label>
                            <Form.Control
                              required
                              type="email"
                              name="email"
                              autoComplete="email"
                              value={values.email}
                              placeholder={translate('components.Signup.EMAIL_INPUT_PLACEHOLDER')}
                              className={
                                classnames({ [FORM_INPUT_ERROR_CLASS]: errors.email && touched.email })
                              }
                              disabled={invitationData || isLoading}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="email" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                          </Form.Group>
                        )
                      )}
                      <Form.Group as={Col} sm={12} md={6} className={PHONE_FORM_GROUP_CLASS}>
                        <Form.Label>* {translate('components.Signup.MOBILE_PHONE_NUMBER_LABEL')}</Form.Label>
                        <Form.Group>
                          <Select
                            isMulti={false}
                            classNamePrefix={DARK_GRAY_BORDERED_SELECT_CLASS}
                            value={values.phoneCountry}
                            options={phoneCountryDropdownOptions}
                            placeholder={translate('components.Signup.PHONE_COUNTRY_SELECT_PLACEHOLDER')}
                            onBlur={handlePhoneCountryBlur({ setFieldTouched })}
                            onChange={handlePhoneCountryChange({ setFieldValue })}
                          />
                          <Form.Control
                            required
                            type="text"
                            name="mobilePhone"
                            autoComplete="tel"
                            value={values.mobilePhone}
                            placeholder={translate('components.Signup.MOBILE_PHONE_NUMBER_INPUT_PLACEHOLDER')}
                            className={
                              classnames({ [FORM_INPUT_ERROR_CLASS]: errors.mobilePhone && touched.mobilePhone })
                            }
                            disabled={isLoading}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <ErrorMessage name="mobilePhone" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} sm={12} md={6}>
                        <Form.Label>* {translate('components.Signup.PASSWORD_LABEL')}</Form.Label>
                        <InputGroup as={Col}>
                          <FormControl
                            required
                            name="password"
                            type={passwordFieldType}
                            value={values.password}
                            placeholder={translate('components.Signup.PASSWORD_INPUT_PLACEHOLDER')}
                            className={
                              classnames({ [FORM_INPUT_ERROR_CLASS]: errors.password && touched.password })
                            }
                            disabled={isLoading}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <InputGroup.Append> {renderPasswordVisibilityIcon()} </InputGroup.Append>
                        </InputGroup>
                        <ErrorMessage name="password" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                      </Form.Group>
                      <Form.Group as={Col} sm={12} md={6}>
                        <Form.Label>* {translate('components.Signup.CONFIRM_PASSWORD_LABEL')}</Form.Label>
                        <InputGroup as={Col}>
                          <FormControl
                            required
                            name="confirmPassword"
                            autoComplete="new-password"
                            type={passwordFieldType}
                            value={values.confirmPassword}
                            placeholder={translate('components.Signup.REPEAT_PASSWORD_INPUT_PLACEHOLDER')}
                            className={
                              classnames({ [FORM_INPUT_ERROR_CLASS]: errors.confirmPassword && touched.confirmPassword })
                            }
                            disabled={isLoading}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <InputGroup.Append> {renderPasswordVisibilityIcon()} </InputGroup.Append>
                        </InputGroup>
                        <ErrorMessage name="confirmPassword" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                      </Form.Group>
                    </Row>
                    <Row className={SIGNUP_FORM_SUBMIT_SECTION_CLASS}>
                      <Button
                        type="submit"
                        disabled={isLoading || !isValid}
                      >
                        {translate('components.Signup.SIGNUP')}
                      </Button>
                      <Button
                        variant="link"
                        onClick={handleOnSignInButtonClick}
                      >
                        {translate('components.Signup.LOGIN_CAPTION_LINK')}
                      </Button>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Container>
          </Container>
        </Col>
      </Row>
    </Container>
  )
})

// export default SignUp

SignUp.propTypes = {
  history: PropTypes.shape({}).isRequired,
  onSignUp: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

SignUp.defaultProps = {
  isLoading: false
}

export const mapStateToProps = (stateProps, ownProps) => {
  const isLoading = isLoadingSelector(stateProps)
  return {
    isLoading,
    ...ownProps
  }
}

export const mapDispatchToProps = { signUp }

const mergeProps = (stateProps, { signUp }, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    onSignUp: ({ firstname, lastname, email, phoneCountry, mobilePhone, password }) => signUp(
      { firstname, lastname, email, country_code: phoneCountry.value, phone: mobilePhone, password }
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SignUp)
