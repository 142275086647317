import { useRef } from 'react'
import PropTypes from 'prop-types'

export default function ButtonFile(props) {
  const { label, setValue, acceptTypes, className, children } = props

  const fileInputRef = useRef()

  const handleChange = (e) => {
    // console.log(e.target.files[0])
    setValue(e.target ? e.target.files[0] : null)
  }

  return (
    <>
      <button type="button" onClick={() => fileInputRef.current.click()} className={className}>
        {label}{children}
      </button>
      <input onChange={handleChange} multiple={false} ref={fileInputRef} type="file" accept={acceptTypes} hidden />
    </>
  )
}

ButtonFile.propTypes = {
  label: PropTypes.string,
  acceptTypes: PropTypes.string,
  className: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  children: PropTypes.node
}

ButtonFile.defaultProps = {
  label: '',
  acceptTypes: 'image/*',
  children: <></>,
  className: undefined
}
