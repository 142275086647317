import './index.scss'
import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import translate from '../../i18next'
import ModalDialog from '../common/ModalDialog'
import { deleteUser } from '../../api/admin'
import SpinnerCustom from '../common/SpinnerCustom'
import MobileCard from '../common/MobileCard'
import useWindowSize from '../../hooks/useWindowSize'

import { ReactComponent as Sort } from '../../assets/icons/sort.svg'
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-solid.svg'

const ROOT_CLASS = 'dealme-users-list'
const TABLE = `${ROOT_CLASS}-table`
const ROW = `${ROOT_CLASS}-row`
const EXPIRED = `${ROOT_CLASS}-status-0`
const ACCEPTED = `${ROOT_CLASS}-status-1`
const PENDING = `${ROOT_CLASS}-status-2`
const DEACTIVATED = `${ROOT_CLASS}-status-3`
const CLOSE_BUTTON_CLASS = `${ROOT_CLASS}-close-button`

const UserList = ({ items, setItems, isLoading, itemsPerPage, setItemsPerPage, page, setPage }) => {
  const size = useWindowSize()
  const [sortCriteria, setSortCriteria] = useState(false)
  const [double, setDouble] = useState(true)
  const [deleteItem, setDeleteItem] = useState(null)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [isLoadingDelete, setLoadingDelete] = useState(false)

  const sortBy = (criteria) => {
    if (sortCriteria === criteria) {
      setDouble(!double)
      const newListOrder = [...items].reverse()
      setItems(newListOrder)
    } else {
      setDouble(false)
      setSortCriteria(criteria)
      setItems(items.sort((a, b) => {
        const varA = Object.prototype.toString.call(a[criteria]) === '[object String]' ? a[criteria].toLowerCase() : a[criteria]
        const varB = Object.prototype.toString.call(b[criteria]) === '[object String]' ? b[criteria].toLowerCase() : b[criteria]
        if (varA < varB) return -1
        return (varA > varB) ? 1 : 0
      }))
    }
  }

  useEffect(() => {
    if (items.length > 0 && !sortCriteria) {
      setDouble(false)
      setSortCriteria('firstname')
      setItems(items.sort((a, b) => {
        if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) return -1
        return (a.firstname.toLowerCase() > b.firstname.toLowerCase()) ? 1 : 0
      }))
    }
  }, [items, sortCriteria])

  const handleRemove = () => {
    if (deleteItem) {
      setLoadingDelete(true)
      deleteUser(deleteItem.email)
        .then((res) => {
          if (res.success) {
            toast.success(translate('components.AccountSettings.DELETE_MANAGER'))
            // setItems([...items.filter((u) => u.email !== deleteItem.email)])
            setItems([...items.filter((u) => u.email !== deleteItem.email), { ...deleteItem, state: 3 }])
            // if (sortCriteria) sortBy(sortCriteria)
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          }
        })
        .catch((error) => {
          if (error.response && error.response.status !== 401 && error.response.data.message) {
            // setError(error.response)
            // } else if (error.response && error.response.data.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
            console.error('error delete item', error)
          }
        }).finally(() => {
          setLoadingDelete(false)
        })
    }
  }

  const getStatusText = (state) => {
    if (state === 0) return 'EXPIRED'
    if (state === 3) return 'DELETED'
    return state === 1 ? 'ACTIVE' : 'PENDING'
  }

  const getStatusClass = (state) => {
    if (state === 0) return EXPIRED
    if (state === 3) return DEACTIVATED
    return state === 1 ? ACCEPTED : PENDING
  }
  const getStatusColor = (state) => {
    if (state === 0) return { 'status-0': true }
    if (state === 3) return { 'status-3': true }
    return state === 1 ? { 'status-1': true } : { 'status-2': true }
  }

  return (
    <>
      {isLoadingDelete && <SpinnerCustom />}
      <div className={ROOT_CLASS}>
        {!isLoading
          ? (items.length > 0
            && (size.width > 991
              ? (
                <>
                  <table className={`${TABLE} d-none d-lg-table px-2`}>
                    <tbody>
                      <tr>
                        <th id="firstname" onClick={() => sortBy('firstname')}>
                          NAME {sortCriteria === 'firstname'
                          ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                          : <Sort className="ml-1 mb-1" />}
                        </th>
                        <th id="email" onClick={() => sortBy('email')}>
                          EMAIL {sortCriteria === 'email'
                          ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                          : <Sort className="ml-1 mb-1" />}
                        </th>
                        <th id="user_type" onClick={() => sortBy('user_type')}>
                          ROLE {sortCriteria === 'user_type'
                          ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                          : <Sort className="ml-1 mb-1" />}
                        </th>
                        <th id="state" onClick={() => sortBy('state')}>
                          STATUS {sortCriteria === 'state'
                          ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                          : <Sort className="ml-1 mb-1" />}
                        </th>
                        <th id="delete"> </th>
                      </tr>
                      {items.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item) => (
                        <tr key={item.email} className={ROW}>
                          <td>{`${item.firstname} ${item.lastname}`}</td>
                          <td>{item.email}</td>
                          <td>{item?.user_type}</td>
                          <td>
                            <div className={getStatusClass(item.state)}>
                              {getStatusText(item.state)}
                            </div>
                          </td>
                          <td className="text-right">
                            { item.user_type !== 'super-admin' && item.state === 1 && (
                              <button
                                type="button"
                                className={CLOSE_BUTTON_CLASS}
                                onClick={() => { setDeleteItem(item); setOpenConfirmDelete(true) }}
                              >
                                <img src="/delete2.png" alt="X" />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-none d-lg-flex p-2 justify-content-between">
                    <div className="pagination">
                      {items.length > itemsPerPage && Array.from({ length: Math.ceil(items.length / itemsPerPage) }, (_, i) => i + 1)
                        .map((pageNumber) => (
                          <button
                            type="button"
                            key={pageNumber}
                            className={classnames('page-link', { active: pageNumber === page })}
                            onClick={() => setPage(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        ))}
                    </div>
                    <div className="show-selector-container">
                      <label htmlFor>
                        <span className="mr-4 text-white">Show:</span>
                        <select value={itemsPerPage} onChange={({ target }) => setItemsPerPage(target.value)}>
                          <option value={10}>10</option>
                          <option value={24}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </label>
                    </div>
                  </div>
                </>
              )
              : (
                <div className="d-block d-lg-none overflow-hidden p-2 mobile-home">
                  {items.map((item) => (
                    <MobileCard
                      item={
                        {
                          name: `${item.firstname} ${item.lastname}`,
                          email: item.email,
                          status: getStatusText(item.state),
                          role: item.user_type
                        }
                      }
                      statusColor={getStatusColor(item.state)}
                    />
                  ))}
                </div>
              )
            )
          )
          : <SpinnerCustom />}
      </div>

      {(openConfirmDelete
        && (
        <ModalDialog show={openConfirmDelete} setShow={setOpenConfirmDelete} title="" setActionOk={handleRemove}>
          {`${translate('components.Admin.DELETE_USER_CONFIRM')} ${deleteItem.firstname} ${deleteItem.lastname}`} ?
        </ModalDialog>
        )
      )}
    </>
  )
}

export default UserList

UserList.propTypes = {
  items: PropTypes.instanceOf(Array),
  setItems: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  itemsPerPage: PropTypes.number.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number,
  setPage: PropTypes.func.isRequired
}

UserList.defaultProps = {
  items: [],
  isLoading: false,
  page: 1
}
