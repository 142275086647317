import './AdminModal.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button, Row, Col, Form, Container } from 'react-bootstrap'
import { Formik, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { addAdmin } from '../../api/admin'
import translate from '../../i18next'

const ROOT_CLASS = 'dealme-manager-modal'
const WRAPPER_CLASS = `${ROOT_CLASS}-wrapper`
const ACCOUNT_SETTINGS_FORM_CLASS = `${ROOT_CLASS}-form`
const SUBMIT_BUTTON_CLASS = `${ROOT_CLASS}-submit-button`
const FORM_INPUT_ERROR_MESSAGE_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-input-error-message`
const FORM_INPUT_ERROR_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-input-error`
const SUBMIT_BUTTON_CONTAINER_CLASS = `${ROOT_CLASS}-submit-button-container`

const INITIAL_FORM_VALUES = {
  firstname: '',
  lastname: '',
  email: '',
  message: ''
}

const FORM_SCHEMA = Yup.object().shape({
  firstname: Yup.string()
    .required('Required'),
  lastname: Yup.string()
    .max(45, 'Maximum of 45 chars')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  message: Yup.string()
    .max(500, 'Maximum of 500 chars')
    .required('Required')
})

export default function AdminModal({ onSubmit }) {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (data, actions) => {
    addAdmin(data)
      .then(() => {
        toast.success(translate('components.Common.INVITATION_SENT'))
        actions.setSubmitting(false)
        actions.resetForm()
        onSubmit(data)
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401 && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          console.error('error add manager', error)
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Container className={ROOT_CLASS}>
      <div className={WRAPPER_CLASS}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={INITIAL_FORM_VALUES}
          validationSchema={FORM_SCHEMA}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <Form noValidate className={ACCOUNT_SETTINGS_FORM_CLASS} onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} sm={12} md={6} lg={6}>
                  <Form.Label>{translate('components.AccountSettings.FIRST_NAME_LABEL')}</Form.Label>
                  <Form.Control
                    autoFocus
                    type="text"
                    name="firstname"
                    value={values.firstname}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.firstname && touched.firstname })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="firstname" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={6} lg={6}>
                  <Form.Label>{translate('components.AccountSettings.LAST_NAME_LABEL')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={values.lastname}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.lastname && touched.lastname })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="lastname" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} sm={12} md={6} lg={6}>
                  <Form.Label>{translate('components.AccountSettings.EMAIL_LABEL')}</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={values.email}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.email && touched.email })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="email" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} sm={12} md={12} lg={12}>
                  <Form.Label>* {translate('components.AccountSettings.MESSAGE')}</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows="8"
                    name="message"
                    maxlength="500"
                    value={values.message}
                    className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.message && touched.message })}
                    placeholder={translate('components.AccountSettings.MESSAGE_PLACEHOLDER')}
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="lastname" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
              </Row>
              <div className={SUBMIT_BUTTON_CONTAINER_CLASS}>
                <Button
                  type="submit"
                  className={SUBMIT_BUTTON_CLASS}
                >
                  {translate('components.AccountSettings.SUBMIT_BUTTON_CAPTION')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

AdminModal.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
