import './index.scss'
import PropTypes from 'prop-types'

const InputFilter = ({ onChange, placeholder, iconSearch, name }) => {
  return (
    <div className="col-sm-6 input-filter pl-2">
      <input
        name={name}
        type="text"
        className="form-control"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      {iconSearch && <img src="/search.png" alt="" />}
    </div>
  )
}

export default InputFilter

InputFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  iconSearch: PropTypes.bool
}

InputFilter.defaultProps = {
  placeholder: 'Search',
  name: 'search-input',
  iconSearch: false
}
