import { apiCall } from './api_client'
import { INFLUENCER_INVITATION, INVITATION } from './auth.routes'

export const getManagerInvitations = (immediately = false) => apiCall(INFLUENCER_INVITATION, {
  method: 'GET',
  immediately,
})

export const addManager = (data) => apiCall(INFLUENCER_INVITATION, {
  method: 'POST',
  immediately: true,
  data,
})

export const deleteInfluencerManager = (token) => {
  return apiCall(`${INVITATION}/${token}`, {
    method: 'DELETE',
    immediately: true,
  })
}
