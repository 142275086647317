// import './index.scss'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const MobileCard = ({ item, statusColor, itemFunction, id }) => {
  const arrProps = Object.keys(item)

  const cleanName = (nameKey) => nameKey.toUpperCase().replace('_', ' ')

  return (
    <div className="mobile-card mb-4 mr-2" key={id}>
      <div className={classnames('read-status', statusColor)} />
      {arrProps.length > 0 && arrProps.map((key, i) => (
        <div key={`${item.id}-${i}` || i} className="d-flex justify-content-between align-items-center p-3">
          <strong>{cleanName(key)}</strong>
          <span>{item[arrProps[i]]}</span>
        </div>
      ))}
      {itemFunction && (
        <div className="d-flex align-items-center justify-content-between p-3 buttonCard">
          <strong>{itemFunction.name.toUpperCase()}</strong>
          <button
            type="button"
            onClick={() => { window.scrollTo(0, 0); itemFunction.action() }}
          >
            <img src="/delete2.png" alt="X" />
          </button>
        </div>
      )}
    </div>
  )
}

export default MobileCard

MobileCard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  statusColor: PropTypes.objectOf(PropTypes.any),
  itemFunction: PropTypes.objectOf(PropTypes.any),
  id: PropTypes.string.isRequired
}

MobileCard.defaultProps = {
  statusColor: { readed: false },
  itemFunction: null
}
