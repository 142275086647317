import './Header.scss'

import { useCallback } from 'react'
import { withRouter } from 'react-router-dom'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import { ReactComponent as SmallLogo } from '../../assets/icons/mobile-logo.svg'

import { ReactComponent as Logo } from '../../assets/icons/logo.svg'

import translate from '../../i18next'

const ROOT_CLASS = 'landing-header'
const LOGO_CLASS = `${ROOT_CLASS}-logo`
const SIGN_CONTAINER_CLASS = `${ROOT_CLASS}-sign-container`
const SIGNUP_BUTTON_CLASS = `${ROOT_CLASS}-sign-up-button`
const SIGNIN_BUTTON_CLASS = `${ROOT_CLASS}-sign-in-button`

export const Header = withRouter(({ history }) => {
  const handleSignupButtonClick = useCallback(() => {
    history.push('/signup')
  }, [history])

  const handleSignInButtonClick = useCallback(() => {
    history.push('/signin')
  }, [history])

  return (
    <Navbar className={ROOT_CLASS}>
      <Navbar.Brand href="/">
        <Logo
          className={`${LOGO_CLASS} d-none d-lg-inline`}
          alt={translate('components.Landing.Header.LOGO_ALT')}
        />
        <SmallLogo className="d-inline d-lg-none mt-4" />
      </Navbar.Brand>

      <Navbar.Collapse>
        <Nav className={SIGN_CONTAINER_CLASS}>
          <Button
            onClick={handleSignupButtonClick}
            className={SIGNIN_BUTTON_CLASS}
          >
            { translate('components.Landing.Header.SIGN_UP') }
          </Button>
          <Button
            onClick={handleSignInButtonClick}
            className={SIGNUP_BUTTON_CLASS}
          >
            { translate('components.Landing.Header.SIGN_IN') }
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
})
