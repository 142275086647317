import './index.scss'
import { withRouter, useParams } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import translate from '../../i18next'
import GenericCover from '../../assets/Submission/cover.jpg'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import SubmissionForm from '../SubmissionForm'

const ROOT_CLASS = 'dealme-submission'
const LEFT_SIDE_CONTAINER_CLASS = `${ROOT_CLASS}-left-side-container`
const LEFT_SIDE_CONTAINER_COVER_IMG_CLASS = `${LEFT_SIDE_CONTAINER_CLASS}-cover-img`
const RIGHT_SIDE_CONTAINER_CLASS = `${ROOT_CLASS}-right-side-container`
const RIGHT_SIDE_CONTAINER_LOGO_CLASS = `${RIGHT_SIDE_CONTAINER_CLASS}-logo`
const RIGHT_SIDE_INNER_CONTAINER_CLASS = `${ROOT_CLASS}-right-side-inner-container`

const Submission = withRouter(({ history }) => {
  const [image, setImage] = useState(null)

  const handleOnHomeIconClick = useCallback(() => {
    history.push('/')
  }, [history])
  const { id } = useParams()
  useEffect(() => {
    if (!id) {
      history.push('/404')
    }
  }, [id])

  return (
    <Container fluid className={ROOT_CLASS}>
      <Row>
        {
          image ? (
            <Col sm={12} md={6} className={LEFT_SIDE_CONTAINER_CLASS}>
              <img
                src={image || GenericCover}
                className={LEFT_SIDE_CONTAINER_COVER_IMG_CLASS}
                alt={translate('components.Submission.GENERIC_COVER_ALT')}
              />
            </Col>
          ) : null
        }
        <Col sm={12} md={image ? 'auto' : 12} className={RIGHT_SIDE_CONTAINER_CLASS}>
          <Container className={RIGHT_SIDE_INNER_CONTAINER_CLASS}>
            <Logo
              className={RIGHT_SIDE_CONTAINER_LOGO_CLASS}
              onClick={handleOnHomeIconClick}
            />
            <SubmissionForm submissionFormSlug={id} history={history} setImage={setImage} />
            )

          </Container>
        </Col>
      </Row>
    </Container>
  )
})

export default Submission
