import './index.scss'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
// import { toast } from 'react-toastify'
import withSideBarMenu from '../SideBarMenu/withSideBarMenu'
import translate from '../../i18next'
import AdminModal from './AdminModal'
// import useDebounce from '../../hooks/useDebounce'
import useUser from '../../hooks/useUser'

import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg'
// import useFetch from '../../hooks/useFetch'
import { getUsers } from '../../api/admin'
import UserList from '../UserList'
import InputFilter from '../InputFilter'

const ROOT_CLASS = 'dealme-admin-users'
const OVERLAY_CLASS = `${ROOT_CLASS}-overlay`
const OVERLAY_HIDDEN_CLASS = `${ROOT_CLASS}-overlay-hidden`
const ACCOUNT_SETTINGS_FORM_CLASS = `${ROOT_CLASS}-form`
const CLOSE_BUTTON_CLASS = `${ROOT_CLASS}-close-button`
const ADD_ADMIN_BUTTON_OPTIONS_CONTAINER_CLASS = `${ROOT_CLASS}-manager-button-options-container`
const ADD_ADMIN_MODAL_CLASS = `${ROOT_CLASS}-manager-modal`
const ADD_ADMIN_MODAL_TITLE_WRAPPER_CLASS = `${ROOT_CLASS}-manager-modal-title-wrapper`
const ADD_ADMIN_MODAL_CLASS_HIDDEN = `${ROOT_CLASS}-manager-modal-hidden`
const ADD_ADMIN_BUTTON_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-manager-button`
const DOWNLOAD_BUTTON_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-download-button`

const AdminUsers = () => {
  const { userData } = useUser()
  const [openAddAdmin, setOpenAddAdmin] = useState(false)

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState([])
  // const { result: usersFetch, isLoading: isLoadingUsers } = useFetch(getUsers, [false], [users.length]) // update when change managers.length
  // const { result: usersFetch, isLoading: isLoadingUsers, error } = useFetch(getUsers, [false], []) // not automatically updated
  const [usersFetch, setUsersFetch] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  // const [lastData, setLastData] = useState('')
  // const [searchValue, setSearchValue] = useState('')
  // const debouncedData = useDebounce(searchValue, 500) // each 0.5 second

  // Effect for API call debounced
  // useEffect(
  //   () => {
  //     // console.log('useEffect debouncedData', debouncedData)
  //     if (debouncedData !== lastData) {
  //       // saveData(data)
  //       // console.log('debouncedData', debouncedData)
  //       setLastData(debouncedData)
  //       setSearchValue('')
  //     }
  //   },
  //   [debouncedData] // Only call effect if debounced search term changes
  // )

  const exportToCsv = () => {
    const filename = 'dealme_users'
    const getStatusText = (state) => {
      if (state === 0) return 'EXPIRED'
      if (state === 3) return 'DELETED'
      return state === 1 ? 'ACTIVE' : 'PENDING'
    }
    const data = [['Name', 'Email', 'Role', 'Status']].concat(users.map((item) => Object.values({
      name: `${item.firstname} ${item.lastname}`,
      email: item.email,
      role: item.user_type,
      status: getStatusText(item.state),
    })))
    // convert every value to String. escape double colons. quote it. comma-separated // rows starting on new lines
    const csv = data.map((row) => row.map(String)
      .map((v) => v.replaceAll('"', '""'))
      .map((v) => `"${v}"`)
      .join(';'))
      .join('\r\n')
    // console.log('export CSV')
    // console.log(csv)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  // useEffect(() => {
  //   if (usersFetch) setUsers(usersFetch)
  //   if (error && (!error.response || (error.response && error.response.status !== 401))) setError(error)
  // }, [usersFetch, error])

  useEffect(() => {
    let mounted = true
    setIsLoading(true)
    getUsers(true).then((res) => {
      if (mounted) {
        setUsers(res.data)
        setUsersFetch(res.data)
        setIsLoading(false)
      }
    })
    return () => { mounted = false }
  }, [])

  const handleSearch = (searchValue) => {
    if (searchValue === '' || searchValue.length < 2) {
      setUsers(usersFetch)
    } else {
      const searchKey = searchValue.toLowerCase()
      setUsers(
        usersFetch.filter(
          (x) => x.firstname.toLowerCase().includes(searchKey)
          || x.lastname.toLowerCase().includes(searchKey)
          || x.email.toLowerCase().includes(searchKey)
          || x.user_type.includes(searchKey)
        )
      )
      setPage(1)
    }
  }

  return (
    <>
      <div className={ROOT_CLASS}>
        {((userData.user_type === 'admin' || userData.user_type === 'super-admin')
          && (
            <>
              <div className={openAddAdmin ? OVERLAY_CLASS : OVERLAY_HIDDEN_CLASS} />
              <div className={openAddAdmin ? ADD_ADMIN_MODAL_CLASS : ADD_ADMIN_MODAL_CLASS_HIDDEN}>
                <div className={ADD_ADMIN_MODAL_TITLE_WRAPPER_CLASS}>
                  <h2>{translate('components.Admin.ADD_ADMIN')}</h2>
                  <button type="button" className={CLOSE_BUTTON_CLASS} onClick={() => setOpenAddAdmin(false)}>
                    <img src="/close-button.png" alt="X" />
                  </button>
                </div>
                <AdminModal
                  onSubmit={(item) => {
                    setOpenAddAdmin(false)
                    const tmpList = [...users, {
                      firstname: item.firstname,
                      lastname: item.lastname,
                      email: item.email,
                      user_type: 'admin (pending)',
                      status: 2,
                    }]
                    setUsers(tmpList)
                    setUsersFetch(tmpList)
                  }}
                />
              </div>
            </>
          )
          )}
        <div className={ADD_ADMIN_BUTTON_OPTIONS_CONTAINER_CLASS}>
          <h1>{translate('components.Admin.TITLE')}</h1>
        </div>
        <div className={ADD_ADMIN_BUTTON_OPTIONS_CONTAINER_CLASS}>
          <InputFilter onChange={handleSearch} />
          <div className="buttons-admin">
            {((userData.user_type === 'admin' || userData.user_type === 'super-admin')
              && (
              <>
                <Button type="button" className={DOWNLOAD_BUTTON_CLASS} onClick={exportToCsv}><DownloadIcon /></Button>
                <Button type="button" className={ADD_ADMIN_BUTTON_CLASS} onClick={() => setOpenAddAdmin(true)}>
                  <img src="/plus.png" alt="+" /> {translate('components.Admin.ADD_ADMIN')}
                </Button>
              </>
              ))}
          </div>
        </div>
        {((userData.user_type === 'admin' || userData.user_type === 'super-admin')
          && (
            <UserList
              items={users}
              setItems={(data) => { setUsers(data); setUsersFetch(data) }}
              isLoading={isLoading}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              page={page}
              setPage={setPage}
            />
          ))}
      </div>
    </>
  )
}

export default withSideBarMenu({ defaultCollapsed: false })(AdminUsers)
