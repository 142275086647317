import './index.scss'
import 'react-toastify/dist/ReactToastify.css'
import './sass/_toast_variables.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

import configureStore from './store/store'
import publicAppRoutes from './public.routes'
import privateAppRoutes from './private.routes'
import PrivateRoute from './components/PrivateRoute'
import UserProvider from './context/user'

import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <Provider store={configureStore}>
        <BrowserRouter>
          <ToastContainer
            theme="colored"
            autoClose={4000}
            closeButton={false}
            position="top-center"
          />
          <Switch>
            { publicAppRoutes.map(({ path, component }) => (<Route key={path} exact path={path} component={component} />))}
            { privateAppRoutes.map(({ path, component }) => (<PrivateRoute key={path} exact path={path} component={component} />))}
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </Provider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
