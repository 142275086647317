/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { Button } from 'react-bootstrap'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg'
import useUser from '../../hooks/useUser'
import './index.scss'

const ROOT_CLASS = 'filters'
const DOWNLOAD_BUTTON_CLASS = `${ROOT_CLASS}-download-button`

const Filters = ({ /* apliedFilters, */ applyFilters, submissions, removeFilters }) => {
  const exportToCsv = () => {
    const filename = 'dealme_submissions'
    const data = [['Company', 'Inquiry Type', 'Amount', 'Name', 'Contact Email', 'Network', 'Handle', 'Timestamp']].concat(submissions.map((item) => Object.values({
      company: item?.company,
      InquieryType: item?.inquiries_types_label,
      amount: item?.amount,
      contactName: item?.contact_name,
      contactEmail: item?.contact_email,
      network: item?.network,
      accountHandle: item?.account_handle,
      timestamp: new Date(item?.created_at).toDateString(),
    })))

    // convert every value to String. escape double colons. quote it. comma-separated // rows starting on new lines
    const csv = data.map((row) => row.map(String)
      .map((v) => v.replaceAll('"', '""'))
      .map((v) => `"${v}"`)
      .join(';'))
      .join('\r\n')
    // console.log('export CSV')
    // console.log(csv)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
  const { userData } = useUser()
  return (
    <div className={`${ROOT_CLASS} p-3`}>
      <label className="form-label">
        <input
          name="company"
          type="text"
          className="form-control"
          placeholder="Search"
          onChange={(e) => applyFilters({ key: 'company', value: e.target.value })}
        />
      </label>
      <label className="form-label">
        <span className="text-white">From</span>
        <input name="from" type="date" className="form-control date" onChange={(e) => applyFilters({ key: 'from', value: e.target.value })} />
      </label>
      <label className="form-label">
        <span className="text-white">To</span>
        <input name="to" type="date" className="form-control date" onChange={(e) => applyFilters({ key: 'to', value: e.target.value })} />
      </label>
      <label className="form-label">
        <span className="text-white">Network</span>
        <select onChange={(e) => (e.target.value === '' ? removeFilters({ key: 'network' }) : applyFilters({ key: 'network', value: e.target.value }))}>
          <option value="" key={0}>All</option>
          {[...new Set(submissions.map(({ network }) => network))].map((network) => <option value={network} key={network}>{network}</option>)}
        </select>
      </label>
      <label className="form-label">
        <span className="text-white">Handle</span>
        <select onChange={(e) => (e.target.value === '' ? removeFilters({ key: 'handle' }) : applyFilters({ key: 'handle', value: e.target.value }))}>
          <option value="" key={0}>All</option>
          {[...new Set(submissions.map((submission) => submission.account_handle))].map((handle) => <option value={handle} key={handle}>{handle}</option>)}
        </select>
      </label>
      {((userData.user_type === 'admin' || userData.user_type === 'super-admin')
              && (
              <Button type="button" className={DOWNLOAD_BUTTON_CLASS} onClick={exportToCsv} disabled={!(submissions && submissions.length > 0)}>
                <DownloadIcon />
              </Button>
              ))}
    </div>
  )
}

export default Filters
