/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { useState } from 'react'
import { ReactComponent as FiltersArrow } from '../../assets/icons/filters-arrow.svg'
import './index.scss'

const ROOT_CLASS = 'filters-mobile'

const FiltersMobile = ({ apliedFilters, applyFilters, submissions, removeFilters }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [localFilters, setLocalFilters] = useState([...apliedFilters])
  const applyLocalFilters = (filter) => {
    const newApliedFilters = [...apliedFilters.filter((apliedFilter) => apliedFilter.key !== filter.key), filter]
    setLocalFilters(newApliedFilters)
  }
  const removeLocalFilters = (filter) => {
    const newApliedFilters = [...apliedFilters.filter((apliedFilter) => apliedFilter.key !== filter.key)]
    setLocalFilters(newApliedFilters)
  }

  return isModalOpen ? (
    <div className="modal-container">
      <div className="modal-deal">
        <button type="button" className="close" aria-label="Close" onClick={() => setIsModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
        <div className={`${ROOT_CLASS} mt-5`}>
          <label className="form-label w-100">
            <input
              name="company"
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={(e) => applyLocalFilters({ key: 'company', value: e.target.value })}
              value={localFilters.find((filter) => filter.key === 'company')?.value}
            />
          </label>
          <label className="form-label w-100">
            <span className="text-white">From</span>
            <input
              name="from"
              type="date"
              className="form-control date"
              onChange={(e) => applyLocalFilters({ key: 'from', value: e.target.value })}
              value={localFilters.find((filter) => filter.key === 'from')?.value}
            />
          </label>
          <label className="form-label w-100">
            <span className="text-white">To</span>
            <input
              name="to"
              type="date"
              className="form-control date"
              onChange={(e) => applyLocalFilters({ key: 'to', value: e.target.value })}
              value={localFilters.find((filter) => filter.key === 'to')?.value}
            />
          </label>
          <label className="form-label w-100">
            <span className="text-white">Network</span>
            <select
              onChange={(e) => (e.target.value === '' ? removeLocalFilters({ key: 'network' }) : applyLocalFilters({ key: 'network', value: e.target.value }))}
              value={localFilters.find((filter) => filter.key === 'network')?.value}
            >
              <option value="" key={0}>All</option>
              {[...new Set(submissions.map(({ network }) => network))].map((network) => <option value={network} key={network}>{network}</option>)}
            </select>
          </label>
          <label className="form-label w-100">
            <span className="text-white">Handle</span>
            <select
              onChange={(e) => (e.target.value === '' ? removeLocalFilters({ key: 'handle' }) : applyLocalFilters({ key: 'handle', value: e.target.value }))}
              value={localFilters.find((filter) => filter.key === 'handle')?.value}
            >
              <option value="" key={0}>All</option>
              {[...new Set(submissions.map((submission) => submission.account_handle))].map((handle) => <option value={handle} key={handle}>{handle}</option>)}
            </select>
          </label>
        </div>
        <button
          type="button"
          className="btn btn-block mt-3 text-white"
          style={{ backgroundColor: 'rgb(120, 190, 33)' }}
          onClick={() => {
            localFilters.forEach((filter) => applyFilters(filter))
            setIsModalOpen(false)
          }}
        >
          Apply
        </button>
        <button
          type="button"
          className="btn btn-danger btn-block mt-3"
          onClick={() => {
            localFilters.forEach((filter) => removeLocalFilters(filter))
            apliedFilters.forEach((filter) => removeFilters(filter))
            setIsModalOpen(false)
          }}
        >
          Remove
        </button>
      </div>
    </div>
  ) : (
    <div className={`${ROOT_CLASS} mb-2`}>
      <div className="d-flex justify-content-between">
        <div>{submissions.length} cards</div>
        <div className="filter-modal-opener" onClick={() => setIsModalOpen(true)}>FILTER <FiltersArrow className="ml-2" /></div>
      </div>
    </div>
  )
}

export default FiltersMobile
