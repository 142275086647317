import './index.scss'
import classnames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { Button, Dropdown, Row, InputGroup, FormControl, Col, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import withSideBarMenu from '../SideBarMenu/withSideBarMenu'
import countries from '../../lib/CountryList'
import translate from '../../i18next'
import ManagerModale from './ManagerModal'
import useUser from '../../hooks/useUser'
import { deleteUser, updateData, uploadImage } from '../../api/user'
import ImageAvatar from '../common/ImageAvatar'
import ModalDialog from '../common/ModalDialog'
import ButtonFile from '../common/ButtonFile'
import imageProfile from '../../assets/Profile/profile_empty.png'
import useDebounce from '../../hooks/useDebounce'
import NewPasswordForm from '../ForgotPassword/NewPasswordForm'
import ManagerList from '../ManagerList'
// import useFetch from '../../hooks/useFetch'
import { getManagerInvitations } from '../../api/influencer'
import PhoneForm from './PhoneForm'
import DeactivateAccount from './DeactivateAccount'
import OTPForm from '../OTPForm'
import { Footer } from '../Landing/Footer/Footer'

const ROOT_CLASS = 'dealme-account-setting'
const OVERLAY_CLASS = `${ROOT_CLASS}-overlay`
const OVERLAY_HIDDEN_CLASS = `${ROOT_CLASS}-overlay-hidden`
const WRAPPER_CLASS = `${ROOT_CLASS}-wrapper`
const ACCOUNT_SETTINGS_FORM_CLASS = `${ROOT_CLASS}-form`
const CLOSE_BUTTON_CLASS = `${ROOT_CLASS}-close-button`
const MANAGER_BUTTON_OPTIONS_CONTAINER_CLASS = `${ROOT_CLASS}-manager-button-options-container`
const MANAGER_MODAL_CLASS = `${ROOT_CLASS}-manager-modal`
const MANAGER_MODAL_TITLE_WRAPPER_CLASS = `${ROOT_CLASS}-manager-modal-title-wrapper`
const MANAGER_MODAL_CLASS_HIDDEN = `${ROOT_CLASS}-manager-modal-hidden`
const MANAGER_BUTTON_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-manager-button`
const OPTIONS_BUTTON_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-options-button`
const EDIT_BUTTON_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-edit-button`
const EDIT_BUTTON_PHONE_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-edit-button-phone`
const EDIT_CAMERA_BUTTON_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-edit-camera-button`
const PHONE_FORM_GROUP_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-phone-form-group`
const FORM_INPUT_ERROR_MESSAGE_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-input-error-message`
const FORM_INPUT_ERROR_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-input-error`
const INPUT_EDIT_CONTAINER_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-input-edit-container`
const FORM_INPUT_AVATAR_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-input-avatar`
const BOTTOM_INPUTS_CONTAINER_CLASS = `${ACCOUNT_SETTINGS_FORM_CLASS}-bottom-inputs-container`

const AccountSetting = () => {
  const { userData, setUserData, setError } = useUser()
  const [openManagerModal, setOpenManagerModal] = useState(false)
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [openPhoneModal, setOpenPhoneModal] = useState(false)
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const [viewOTPForm, setViewOTPForm] = useState(false)
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false)
  const [showEditImage, setShowEditImage] = useState(false)
  const [editImage, setEditImage] = useState(null)

  const [managers, setManagers] = useState([])
  // const { result: managersFetch, isLoading: isLoadingManagers } = useFetch(getManagerInvitations, [false], [managers.length]) // update when change managers.length
  // const { result: managersFetch, isLoading: isLoadingManagers } = useFetch(getManagerInvitations, [false], []) // not automatically updated

  const phoneCountryLabel = useMemo(() => {
    if (!userData.country_code) return ''
    const country = countries.filter((c) => c.iso2 === userData.country_code)
    return country[0] ? country[0].dialCode : ''
  }, [countries, userData.country_code])

  const INITIAL_FORM_VALUES = {
    firstname: userData.firstname,
    lastname: userData.lastname,
    email: userData.email,
    phoneCountry: { value: userData.country_code || '', label: `(+${phoneCountryLabel})` },
    mobilePhone: userData.phone ? `(+${phoneCountryLabel}) ${userData.phone}` : '',
    password: '**********',
    avatarSrc: userData.avatar_image || imageProfile
  }

  const [lastData, setLastData] = useState(INITIAL_FORM_VALUES)
  const [formData, setFormData] = useState(INITIAL_FORM_VALUES)
  const [isLoading, setIsLoading] = useState(false)
  const debouncedData = useDebounce(formData, 1000) // each second

  const saveData = (data, password = false) => {
    if (data) {
      setIsLoading(true)
      const dataSave = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phone: data.phone,
        country_code: data.country_code,
        avatar_image: data.avatar_image,
      }
      updateData(password ? { ...dataSave, password } : dataSave)
        .then(() => {
          if (!password) toast.success(translate('components.GetStarted.SAVE_PROFILE_DATA'))
          setUserData(data)
          setLastData(data)
        })
        .catch((error) => {
          if (error.response && error.response.status !== 401 && error.response.data.message) {
            // setError(error.response)
            // } else if (error.response && error.response.data.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
            console.error('error add manager', error)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  // Effect for API call debounced
  useEffect(
    () => {
      // console.log('useEffect debouncedData', debouncedData)
      if (debouncedData.firstname !== lastData.firstname || debouncedData.lastname !== lastData.lastname) {
        const data = {
          ...userData,
          firstname: debouncedData.firstname,
          lastname: debouncedData.lastname
        }
        saveData(data)
      }
    },
    [debouncedData] // Only call effect if debounced search term changes
  )

  // useEffect(() => {
  //   if (managersFetch) {
  //     setManagers(
  //       managersFetch.map((item, i) => ({
  //         firstname: item.firstname ? item.firstname : '-',
  //         lastname: item.lastname ? item.lastname : '-',
  //         company: item.company,
  //         email: item.email,
  //         state: item.invitation ? item.invitation.state : 2,
  //         invitation_token: item.invitation ? item.invitation.invitation_token : i,
  //       }))
  //     )
  //   }
  // }, [managersFetch])

  useEffect(() => {
    let mounted = true
    setIsLoading(true)
    getManagerInvitations(true)
      .then((res) => {
        if (mounted) {
          setManagers(
            res.data.map((item, i) => ({
              firstname: item.firstname ? item.firstname : '-',
              lastname: item.lastname ? item.lastname : '-',
              company: item.company,
              email: item.email,
              state: item.invitation ? item.invitation.state : 2,
              invitation_token: item.invitation ? item.invitation.invitation_token : i,
            }))
          )
          setIsLoading(false)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status !== 404 && error.response.data.message) {
          toast.error(error.response.data.message)
        } else if (error.response && error.response.status === 404) {
          setError(error.response) // logout if user not found
        } else {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          console.error('error add manager', error)
        }
      })
    return () => { mounted = false }
  }, [])

  const update = () => {
    setIsLoading(true)
    getManagerInvitations(true)
      .then((res) => {
        setManagers(
          res.data.map((item, i) => ({
            firstname: item.firstname ? item.firstname : '-',
            lastname: item.lastname ? item.lastname : '-',
            company: item.company,
            email: item.email,
            state: item.invitation ? item.invitation.state : 2,
            invitation_token: item.invitation ? item.invitation.invitation_token : i,
          }))
        )
      })
      .catch((error) => {
        if (error.response && error.response.status !== 404 && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          console.error('error add manager', error)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleNewImage = (file) => {
    setEditImage(file)
    setShowEditImage(true)
    convertBase64(file)
      .then((image64) => {
        uploadImage({ image: image64 })
      })
      .then(() => {
        toast.success(translate('components.GetStarted.SAVE_PROFILE_PICTURE_BUTTON'))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleOnPasswordChanged = (data) => {
    setIsLoading(true)
    const dataSave = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      email: userData.email,
      phone: userData.phone,
      country_code: userData.country_code,
      avatar_image: userData.avatar_image,
      password: data.password
    }
    updateData(dataSave)
      .then(() => {
        setViewOTPForm(true)
        toast.info(translate('components.AccountSettings.SAVE_PROFILE_VALIDATION'))
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401 && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          console.error('error updateData', error)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleOnEmailFormSubmit = (data) => {
    setIsLoading(true)
    const dataSave = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      phone: userData.phone,
      country_code: userData.country_code,
      avatar_image: userData.avatar_image,
      email: data.email,
      password: data.password
    }
    updateData(dataSave)
      .then(() => {
        setLastData(dataSave)
        setViewOTPForm(true)
        toast.info(translate('components.AccountSettings.SAVE_PROFILE_VALIDATION'))
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401 && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          console.error('error updateData', error)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleOnPasswordChangedVerified = () => {
    // console.log('handleOnPasswordChangedVerified', data)
    toast.success(translate('components.ForgotPassword.PASSWORD_CHANGED_FEEDBACK_CAPTION'))
    setOpenPasswordModal(false)
    setOpenEmailModal(false)
    setViewOTPForm(false)
  }

  const handleOnEmailChangedVerified = () => {
    toast.success(translate('components.OTPForm.VALID_PASSCODE_MSG'))
    setError(true) // logout
    // setOpenPasswordModal(false)
    // setOpenEmailModal(false)
    // setViewOTPForm(false)
    // setFormData({ ...formData, email: data.email })
  }

  const handleOnPhoneFormSubmit = (data) => {
    const dataSave = {
      ...userData,
      phone: data.mobilePhone,
      country_code: data.phoneCountry.value
    }
    saveData(dataSave)

    const country = countries.filter((c) => c.iso2 === data.phoneCountry.value)
    setFormData({
      ...formData,
      phoneCountry: data.phoneCountry,
      mobilePhone: data.mobilePhone ? `(+${country[0] ? country[0].dialCode : '1'}) ${data.mobilePhone}` : '-',
    })
    setOpenPhoneModal(false)
  }

  const deactivateAccount = () => {
    setIsLoading(true)
    deleteUser()
      .then(() => {
        toast.success(translate('components.AccountSettings.DELETE_PROFILE'))
        setError('Deactivated Account')
      })
      .catch((error) => {
        if (error.response && error.response.status !== 401 && error.response.data.message) {
          // setError(error.response)
          // } else if (error.response && error.response.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          console.error('error add manager', error)
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <div className={openManagerModal ? OVERLAY_CLASS : OVERLAY_HIDDEN_CLASS} />
      <div className={ROOT_CLASS}>
        {(userData.user_type === 'influencer'
          && (
            <>
              <div className={openManagerModal ? MANAGER_MODAL_CLASS : MANAGER_MODAL_CLASS_HIDDEN}>
                <div className={MANAGER_MODAL_TITLE_WRAPPER_CLASS}>
                  <h2 className="px-3">{translate('components.AccountSettings.ADD_MANAGER')}</h2>
                  <button type="button" className={CLOSE_BUTTON_CLASS} onClick={() => setOpenManagerModal(false)}>
                    <img src="/close-button.png" alt="X" />
                  </button>
                </div>
                <ManagerModale
                  setIsLoading={setIsLoading}
                  onSubmit={() => {
                    setOpenManagerModal(false)
                    update()
                    // setManagers([...managers, {
                    //   firstname: item.firstname,
                    //   lastname: item.lastname,
                    //   company: item.company,
                    //   email: item.email,
                    //   state: 2,
                    //   invitation_token: 0,
                    // }])
                  }}
                />
              </div>
            </>
          )
        )}
        <div className={MANAGER_BUTTON_OPTIONS_CONTAINER_CLASS}>
          <h1>{translate('components.AccountSettings.TITLE')}</h1>
          <div>
            {(userData.user_type === 'influencer'
              && <Button type="button" className={MANAGER_BUTTON_CLASS} onClick={() => setOpenManagerModal(true)}>{translate('components.AccountSettings.ADD_MANAGER')}</Button>
            )}
            {userData.user_type !== 'super-admin' && (
              <Dropdown>
                <Dropdown.Toggle className={OPTIONS_BUTTON_CLASS} id="dropdown-profile">
                  <img src="ellipsis-v.png" alt="options" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-options">
                  <Dropdown.Item
                    as="button"
                    onClick={() => setOpenDeactivateModal(true)}
                  >
                    Deactivate your account
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className={WRAPPER_CLASS}>
          <Formik
            initialValues={formData}
            enableReinitialize
            // validationSchema=""
          >
            {({ values, errors, touched, handleChange, handleBlur, }) => (
              <Form className={ACCOUNT_SETTINGS_FORM_CLASS}>
                <Form.Group as={Col} sm={12} md={6} lg={3}>
                  <Form.Label>{translate('components.AccountSettings.FIRST_NAME_LABEL')}</Form.Label>
                  <Form.Control
                    autoFocus
                    type="text"
                    name="firstname"
                    autoComplete="given-name"
                    value={values.firstname}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.firstname && touched.firstname })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      setFormData({ ...formData, firstname: e.target.value })
                    }}
                  />
                  <ErrorMessage name="firstname" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={6} lg={3}>
                  <Form.Label>{translate('components.AccountSettings.LAST_NAME_LABEL')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    autoComplete="family-name"
                    value={values.lastname}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.lastname && touched.lastname })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      setFormData({ ...formData, lastname: e.target.value })
                    }}
                  />
                  <ErrorMessage name="lastname" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={6} lg={3}>
                  <Form.Label>{translate('components.AccountSettings.EMAIL_LABEL')}</Form.Label>
                  <div className={INPUT_EDIT_CONTAINER_CLASS}>
                    <Form.Control
                      required
                      type="email"
                      name="email"
                      autoComplete="email"
                      defaultValue={values.email}
                      className={
                        classnames({ [FORM_INPUT_ERROR_CLASS]: errors.email && touched.email })
                      }
                      disabled
                      onBlur={handleBlur}
                    />
                    <button type="button" className={EDIT_BUTTON_CLASS} onClick={() => setOpenEmailModal(true)}>
                      <img src="edit.png" alt="edit" />
                    </button>
                  </div>
                  <ErrorMessage name="email" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={6} lg={3} className={PHONE_FORM_GROUP_CLASS}>
                  <Form.Label>{translate('components.AccountSettings.MOBILE_PHONE_NUMBER_LABEL')}</Form.Label>
                  <div className={INPUT_EDIT_CONTAINER_CLASS}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="mobilePhone"
                        autoComplete="tel"
                        // defaultValue={formData.mobilePhone ?? values.mobilePhone}
                        // defaultValue={formData.mobilePhone}
                        value={formData.mobilePhone}
                        className={
                          classnames({ [FORM_INPUT_ERROR_CLASS]: errors.mobilePhone && touched.mobilePhone })
                        }
                        disabled
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                    <button type="button" className={EDIT_BUTTON_PHONE_CLASS} onClick={() => setOpenPhoneModal(true)}>
                      <img src="edit.png" alt="edit" />
                    </button>
                  </div>
                  <ErrorMessage name="mobilePhone" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
                <div className={BOTTOM_INPUTS_CONTAINER_CLASS}>
                  <Form.Group as={Col} sm={12} md={6} lg={3}>
                    <Form.Label> {translate('components.AccountSettings.PASSWORD_LABEL')}</Form.Label>
                    <div className={INPUT_EDIT_CONTAINER_CLASS}>
                      <InputGroup as={Col}>
                        <FormControl
                          name="password"
                          type="{passwordFieldType}"
                          defaultValue={values.password}
                          className={
                            classnames({ [FORM_INPUT_ERROR_CLASS]: errors.password && touched.password })
                          }
                          disabled
                          onBlur={handleBlur}
                        />
                      </InputGroup>
                      <button type="button" className={EDIT_BUTTON_CLASS} onClick={() => setOpenPasswordModal(true)}>
                        <img src="edit.png" alt="edit" />
                      </button>
                    </div>
                    <ErrorMessage name="avatar" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                  <Row className={FORM_INPUT_AVATAR_CLASS}>
                    <Form.Group as={Col} sm={12} md={6} lg={3}>
                      <Form.Label> {translate('components.AccountSettings.AVATAR_LABEL')}</Form.Label>
                      <InputGroup as={Col}>
                        <img
                          src={INITIAL_FORM_VALUES.avatarSrc}
                          alt="Avatar"
                          setValue={handleNewImage}
                          className={imageProfile === INITIAL_FORM_VALUES.avatarSrc && 'not-setted'}
                        />
                        {/* <button type="button" className={EDIT_CAMERA_BUTTON_CLASS} onClick={handleNewImage}>
                          <img src="camera.png" alt="edit" />
                        </button> */}
                        <ButtonFile
                          className={EDIT_CAMERA_BUTTON_CLASS}
                          setValue={handleNewImage}
                        >
                          {imageProfile === INITIAL_FORM_VALUES.avatarSrc ? <img src="camera.png" alt="edit" /> : <div className="position-absolute p-4" /> }
                        </ButtonFile>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {(userData.user_type === 'influencer' && <ManagerList managers={managers} setManagers={setManagers} isLoading={isLoading} />)}
      {(showEditImage
        && (
        <ModalDialog show={showEditImage} setShow={setShowEditImage} title="Edit image">
          <ImageAvatar
            image={editImage}
            saveImage={(img) => saveData({ ...userData, avatar_image: img })}
            closeDialog={() => setShowEditImage(false)}
          />
        </ModalDialog>
        )
      )}
      {(openPasswordModal
        && (
        <ModalDialog show={openPasswordModal} setShow={setOpenPasswordModal} title="Edit Password" onHide={() => setViewOTPForm(false)}>
          {viewOTPForm
            ? <OTPForm onVerified={handleOnPasswordChangedVerified} resendCode={() => handleOnEmailFormSubmit(lastData)} validateChangesToken />
            : <NewPasswordForm onPasswordChanged={handleOnPasswordChanged} />}
        </ModalDialog>
        )
      )}
      {(openEmailModal
        && (
        <ModalDialog show={openEmailModal} setShow={setOpenEmailModal} title="Edit Email" onHide={() => setViewOTPForm(false)}>
          {/* <EmailForm onJumpToOTP={handleJumpToOTPButtonClick} onSubmit={handleOnEmailFormSubmit} /> */}
          {/* <NewPasswordForm onPasswordChanged={handleOnEmailFormSubmit} editEmail /> */}
          {viewOTPForm
            ? <OTPForm onVerified={handleOnEmailChangedVerified} resendCode={() => handleOnEmailFormSubmit(lastData)} validateChangesToken />
            : <NewPasswordForm onPasswordChanged={handleOnEmailFormSubmit} editEmail />}
        </ModalDialog>
        )
      )}
      {(openPhoneModal
        && (
        <ModalDialog show={openPhoneModal} setShow={setOpenPhoneModal} title="Edit Phone">
          <PhoneForm onSubmit={handleOnPhoneFormSubmit} phone={userData.phone} countryCode={userData.country_code} />
        </ModalDialog>
        )
      )}
      {(openDeactivateModal
        && (
        <ModalDialog
          show={openDeactivateModal}
          setShow={setOpenDeactivateModal}
        >
          <DeactivateAccount setActionOk={deactivateAccount} setShow={setOpenDeactivateModal} />
        </ModalDialog>
        )
      )}
      <div className="footer">
        <Footer />
      </div>
    </>
  )
}

export default withSideBarMenu({ defaultCollapsed: false })(AccountSetting)
