import './index.scss'

import { withRouter } from 'react-router-dom'
import { useCallback } from 'react'
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container'

import PropTypes from 'prop-types'

import { toast } from 'react-toastify'

import translate from '../../i18next'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'

import OTPForm from '../OTPForm'

import { requestOneTimePasscode } from '../../store/actions'

const ROOT_CLASS = 'dealme-forgot-password'
const LOGO_CLASS = `${ROOT_CLASS}-logo`
const CAPTION_CONTAINER_CLASS = `${ROOT_CLASS}-caption-container`

// const LOGIN_ERROR_TOAST_AUTOCLOSE = 10 * 1000 // 10 seconds

const ValidateUser = withRouter(({ history, onRequestOnTimePasscode }) => {
  const handleOnHomeIconClick = useCallback(() => {
    history.push('/')
  }, [history])

  const handleOnOTPFormVerified = useCallback((data) => {
    if (data.email) {
      toast.success(translate('components.OTPForm.VALID_PASSCODE_MSG'))
    } else if (data.message) {
      toast.error(data.message)
    }
    history.push(data.email ? `/signin/${data.email}` : '/signin')
  // }, [])
  }, [onRequestOnTimePasscode])

  return (
    <Container className={ROOT_CLASS}>
      <Logo
        className={LOGO_CLASS}
        onClick={handleOnHomeIconClick}
      />
      <Container className={CAPTION_CONTAINER_CLASS}>
        <p>{translate('components.OTPForm.CODE_VALIDATE')}</p>
      </Container>
      <OTPForm onVerified={handleOnOTPFormVerified} userCode />
    </Container>
  )
})

ValidateUser.propTypes = {
  onRequestOnTimePasscode: PropTypes.func.isRequired
}

export const mapStateToProps = (stateProps, ownProps) => {
  return {
    ...stateProps,
    ...ownProps
  }
}

export const mapDispatchToProps = { requestOneTimePasscode }

const mergeProps = (_, { requestOneTimePasscode }, ownProps) => ({
  ...ownProps,
  onRequestOnTimePasscode: (payload) => requestOneTimePasscode(payload)
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ValidateUser)
