import i18next from 'i18next'
import LangDetector from 'i18next-browser-languagedetector'

import en from './en/common.json'

i18next.use(LangDetector).init({
  defaultValue: 'NOT_FOUND',
  interpolation: {
    escape: false,
    escapeValue: false,
  },

  fallbackLng: 'en',
  defaultNS: 'common',

  resources: {
    en: { common: en },
  },
})

const translate = i18next.getFixedT()

export default translate
