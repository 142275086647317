import {
  SIGN_IN,
  SIGNED_IN,
  SIGN_IN_FAILED,
  CHANGE_PASSWORD,
  PASSWORD_CHANGED,
  PASSWORD_CHANGE_FAILED
} from '../actions/user.types'

const DEFAULT_STATE = {}

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { ...state }
    case PASSWORD_CHANGED:
      return { ...state }
    case PASSWORD_CHANGE_FAILED:
      return { ...state }
    case SIGN_IN:
      return { ...state, isLoading: true }
    case SIGNED_IN:
      return { ...state, isLoading: false, ...action.data }
    case SIGN_IN_FAILED:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
