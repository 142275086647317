import axios from 'axios'
import storage from '../helpers/storage'
import { USER_STORAGE_KEY } from '../store/actions/user.types'

const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`

axios.defaults.baseURL = API_URL
axios.defaults.headers['Content-Type'] = 'application/json'

export default axios.create()

const makeCall = (url, options) => axios(url, { ...options })
  .then((res) => {
    // console.log("res", res)
    return res.status >= 300
      ? { success: false, error: res.data }
      : { success: true, data: res.data }
  })
  .catch((err) => {
    // console.log("err", err)
    if (!axios.isCancel(err)) throw err
  })

export const apiCall = async (url, options = {}) => {
  const user = await storage.asyncGet(USER_STORAGE_KEY)
  // console.log('apiCall url', url)
  if (user) axios.defaults.headers.Authorization = options.authorization ? options.authorization : `bearer ${user.token}`
  // console.log('apiCall axios.defaults.headers.Authorization', axios.defaults.headers.Authorization)
  if (options.immediately) return makeCall(url, options)

  const { CancelToken } = axios
  const source = CancelToken.source()

  return {
    abort: () => source.cancel('Operation canceled by the user'),
    ready: () => makeCall(url, { ...options, cancelToken: source.token }),
  }
}

export const addResponseInterceptor = (fn, errFn) => axios.interceptors.response.use(fn, errFn)

export const ejectResponseInterceptor = (interceptor) => axios.interceptors.response.eject(interceptor)

// export default service
