import './index.scss'

import { useCallback } from 'react'
import { withRouter, Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader
} from 'react-pro-sidebar'

import { Row, Container } from 'react-bootstrap'
import useUser from '../../hooks/useUser'
import useWindowSize from '../../hooks/useWindowSize'
import translate from '../../i18next'

import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { ReactComponent as HomeIcon } from '../../assets/icons/home_icon.svg'
import { ReactComponent as BurguerIcon } from '../../assets/icons/burguer_icon.svg'
import { ReactComponent as SupportIcon } from '../../assets/icons/support_icon.svg'
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings_icon.svg'
import { ReactComponent as AdminIcon } from '../../assets/icons/users-icon.svg'
import { ReactComponent as SmallLogo } from '../../assets/icons/small-logo.svg'
import { REACT_APP_SUPPORT_PAGE } from '../../config/config'

const ROOT_CLASS = 'dealme-sidebar'
const SIDEBAR_HEADER_CONTAINER_CLASS = `${ROOT_CLASS}-header-container`

const SideBarMenu = ({ location, isCollapsed, onCollapse }) => {
  const { userData } = useUser()
  const isPageLocation = useCallback((locationToVerify) => location.pathname === locationToVerify, [location])
  const size = useWindowSize()
  const renderHomeIcon = useCallback(() => <HomeIcon fill={isPageLocation('/home') ? '#78BE21' : '#90A4AE'} />, [])
  const renderAdminIcon = useCallback(() => <AdminIcon fill={isPageLocation('/admin-users') ? '#78BE21' : '#90A4AE'} />, [])
  const renderSettingsIcon = useCallback(() => <SettingsIcon fill={isPageLocation('/account-settings') ? '#78BE21' : '#90A4AE'} />, [])
  const renderSupportIcon = useCallback(() => <SupportIcon fill="#78BE21" />, []) // is a external link

  return (
    <>
      <ProSidebar className={`${ROOT_CLASS} d-${isCollapsed ? 'block' : 'none'} d-lg-block`} collapsed={size.width > 989 ? isCollapsed : !isCollapsed}>
        <SidebarHeader>
          <Container className={SIDEBAR_HEADER_CONTAINER_CLASS}>
            <Row>
              <BurguerIcon onClick={onCollapse} />
            </Row>
            <Row>
              {isCollapsed && size.width > 989 && <SmallLogo />}
              {!isCollapsed && size.width > 989 && <Logo />}
              {isCollapsed && size.width <= 989 && (
                <div className="inMobileMenu">
                  {userData.avatar_image && <img src={userData.avatar_image} alt="avatar" />}
                  <h4>{userData.firstname} {userData.lastname}</h4>
                  <p>{userData.email}</p>
                </div>
              )}
            </Row>
          </Container>
        </SidebarHeader>
        <Menu>
          <MenuItem active={isPageLocation('/home')} icon={renderHomeIcon()}>
            <Link to="/home">{translate('components.SideBarMenu.OVERVIEW_LINK_CAPTION')}</Link>
          </MenuItem>
          {(userData.user_type === 'admin' || userData.user_type === 'super-admin')
          && (
            <MenuItem active={isPageLocation('/admin-users')} icon={renderAdminIcon()}>
              <Link to="/admin-users">{translate('components.SideBarMenu.USERS')}</Link>
            </MenuItem>
          )}
          <MenuItem active={isPageLocation('/account-settings')} icon={renderSettingsIcon()}>
            <Link to="/account-settings">{translate('components.SideBarMenu.SETTINGS_LINK_CAPTION')}</Link>
          </MenuItem>
          <MenuItem icon={renderSupportIcon()}>
            {/* <Link to="/support">{translate('components.SideBarMenu.SUPPORT_LINK_CAPTION')}</Link> */}
            <a rel="noopener noreferrer" href={REACT_APP_SUPPORT_PAGE} target="_blank">
              {translate('components.SideBarMenu.SUPPORT_LINK_CAPTION')}
            </a>
          </MenuItem>
        </Menu>
      </ProSidebar>
      <div className="d-block d-lg-none position-absolute p-4">
        <BurguerIcon onClick={onCollapse} />
      </div>
    </>
  )
}

SideBarMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired
}

SideBarMenu.defaultProps = {
  isCollapsed: false
}

export default withRouter(SideBarMenu)
