import './index.scss'

import { useState, useEffect } from 'react'
// import { connect } from 'react-redux'

import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import { Dropdown } from 'react-bootstrap'
import translate from '../../i18next'
// import { notification } from '../../helpers/sendNotification'
import { ReactComponent as BellNotification } from '../../assets/icons/bell_notification.svg'
import { ReactComponent as Bell } from '../../assets/icons/bell.svg'
import { ReactComponent as SignOutIcon } from '../../assets/icons/sign-out-alt-solid.svg'
import { ReactComponent as DropIcon } from '../../assets/icons/home.svg'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import Notifications from '../Notifications'
import imageProfile from '../../assets/Profile/profile_empty.png'
import useUser from '../../hooks/useUser'
import { getNotification } from '../../api/notification'

const ROOT_CLASS = 'dealme-navbar-menu'
const ANIMATE_SHAKE_1_CLASS = 'animate-shake'
const NAV_JUSTIFY_CONTENT_CLASS = 'justify-content-end'
const PROFILE_IMAGE_CLASS = `${ROOT_CLASS}-profile-image`
const PROFILE_INFO_CONTAINER_CLASS = `${ROOT_CLASS}-profile-info-container`
const PROFILE_NOTIFICATION_CONTAINER_CLASS = `${ROOT_CLASS}-profile-notification-container`
const HIDE_NOTIFICATIONS_CLASS = `${ROOT_CLASS}-hide-notifications`
const SHOW_NOTIFICATIONS_CLASS = `${ROOT_CLASS}-show-notifications`
const BELL_NOTIFICATION_CONTAINER_CLASS = `${ROOT_CLASS}-bell-notification-container`

// eslint-disable-next-line
// const TEM_IMAGE_URL = 'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg'

// const NavBarMenu = ({ user, hasNotifications }) => {
export default function NavBarMenu() {
  const { userData, logout } = useUser()
  const [openNotifications, setOpenNotifications] = useState(false)
  // const userFullName = useMemo(() => {
  //   const { firstname, lastname } = user
  //   return `${firstname} ${lastname}`
  // }, [user.firstname, user.lastname])
  // const userRole = useMemo(() => {
  //   const { role } = user
  //   return translate(`components.NavBarMenu.${role}`)
  // }, [user.role])

  // console.log('user', userData)
  const [unreadNotifications, setUnreadNotifications] = useState([])
  const [readNotifications, setReadNotifications] = useState([])
  const hasNotifications = unreadNotifications.length > 0

  useEffect(() => {
    let mounted = true
    getNotification()
      .then(({ data }) => {
        if (mounted) {
          setUnreadNotifications(data.filter((notification) => !notification.readed_at && !notification.dismiss_at).reverse())
          setReadNotifications(data.filter((notification) => notification.readed_at || notification.dismiss_at).reverse())
        }
      })
      .catch((error) => {
        console.log(error)
      })
    // notification(userData.token, userData.email, 'Testing notifications')
    return () => { mounted = false } // cleanup function
  },
  [])

  return (
    <div className={ROOT_CLASS}>
      {openNotifications && <div className="shadow" />}
      <Nav className={NAV_JUSTIFY_CONTENT_CLASS}>
        <Nav.Item>
          <Container>
            <Logo
              className="logo mr-4"
              alt={translate('components.Landing.Header.LOGO_ALT')}
            />
            <div className={PROFILE_NOTIFICATION_CONTAINER_CLASS}>
              <button
                type="button"
                className={BELL_NOTIFICATION_CONTAINER_CLASS}
                onClick={function () { setOpenNotifications(true) }}
              >
                {hasNotifications
                  ? (
                    <BellNotification
                      className={ANIMATE_SHAKE_1_CLASS}
                    />
                  )
                  : <Bell />}
              </button>
              <div className={openNotifications ? SHOW_NOTIFICATIONS_CLASS : HIDE_NOTIFICATIONS_CLASS}>
                <Notifications
                  state={setOpenNotifications}
                  unreadNotifications={unreadNotifications}
                  readNotifications={readNotifications}
                  setUnreadNotifications={setUnreadNotifications}
                  setReadNotifications={setReadNotifications}
                />
              </div>
            </div>
            <div className={PROFILE_INFO_CONTAINER_CLASS}>
              <span>{userData.firstname} {userData.lastname}</span>
              <p>{userData.user_type}</p>
            </div>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-home">
                <DropIcon />
                <img
                  alt={translate('components.NavBarMenu.PROFILE_PICTURE_ALT')}
                  className={PROFILE_IMAGE_CLASS}
                  src={userData.avatar_image ? userData.avatar_image : imageProfile}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-options">
                <Dropdown.Item
                  as="button"
                  onClick={logout}
                >
                  <SignOutIcon alt="" className="logout-svg" /><span className="logout-text"> {translate('components.NavBarMenu.SIGNOUT')} </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </Container>
        </Nav.Item>
      </Nav>
    </div>
  )
}

NavBarMenu.defaultProps = {
  hasNotifications: false
}

// export const mapStateToProps = (stateProps, ownProps) => {
//   return {
//     ...stateProps,
//     ...ownProps
//   }
// }

// export const mapDispatchToProps = {}

// const mergeProps = (_, ownProps) => ({
//   ...ownProps,
//   user: {
//     firstname: 'Martin',
//     lastname: 'Vetrov',
//     role: 'INFLUENCER'
//   }
// })

// export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NavBarMenu)
