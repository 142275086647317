export const getFromStorage = (key) => {
  const value = localStorage.getItem(key)
  try {
    return value ? JSON.parse(value) : null
  } catch (e) {
    console.log('getFromStorage::Value: ', value)
    console.log('getFromStorage::Error: ', e)
    return null
  }
}

export const setToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const asyncGetToStorage = (key) => {
  return Promise.resolve().then(() => {
    const value = localStorage.getItem(key)

    try {
      return value ? JSON.parse(value) : null
    } catch (e) {
      console.log('asyncGetToStorage::Value: ', value)
      console.log('asyncGetToStorage::Error: ', e)
      return null
    }
  })
}

export const asyncSetToStorage = async (key, value) => {
  return Promise.resolve().then(() => {
    localStorage.setItem(key, JSON.stringify(value))
  })
}

export const clearStorage = () => {
  localStorage.clear()
}

const storage = {
  get: getFromStorage,
  set: setToStorage,
  asyncSet: asyncSetToStorage,
  asyncGet: asyncGetToStorage,
  clear: clearStorage,
}

export default storage
