import Signup from './components/Signup'
import SignIn from './components/SignIn'
import NotFound from './components/NotFound'
import TermsOfUse from './components/TermsOfUse'
import ForgotPassword from './components/ForgotPassword'
import PrivacyPolicy from './components/PrivacyPolicy'
import { Landing } from './components/Landing'
import Submission from './components/Submission'
import ValidateUser from './components/ValidateUser'
import Support from './components/Support'

const routes = [
  {
    path: '/',
    component: Landing,
  },
  {
    path: '/submission',
    component: Submission
  },
  {
    path: '/submission/:id',
    component: Submission
  },
  {
    path: '/s/:id',
    component: Submission
  },
  {
    path: '/signup',
    component: Signup,
  },
  {
    path: '/signin',
    component: SignIn
  },
  {
    path: '/signin/:email',
    component: SignIn
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/forgot-password/:forgotcode',
    component: ForgotPassword
  },
  {
    path: '/validate-user',
    component: ValidateUser
  },
  {
    path: '/validate-user/:code',
    component: ValidateUser
  },
  {
    path: '/validate-code/:tokenChangeData',
    component: ValidateUser
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/terms-of-use',
    component: TermsOfUse
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/support',
    component: Support
  },
  {
    path: '/invitation/:idInvitation',
    component: Signup
  },
  {
    path: '/validate-admin/:tokenAdmin',
    component: Signup
  }
]

export default routes
