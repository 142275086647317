/* eslint-disable no-use-before-define */
import './index.scss'

import { connect } from 'react-redux'
import { useCallback, useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import * as Yup from 'yup'
import PropTypes from 'prop-types'

import { Formik } from 'formik'

import classnames from 'classnames'

import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
// import * as clipboard from 'clipboard-polyfill/text'
import translate from '../../i18next'
import { verifyOneTimePasscode, verifyUserValidationCode } from '../../store/actions'
import { isLoadingSelector } from '../../store/selectors/userSelector'
import { validateChanges } from '../../api/user'

const ROOT_CLASS = 'otp-form'
const OTP_FORM_CLASS = `${ROOT_CLASS}-form`
const OTP_FORM_CTA_SECTION_CLASS = `${ROOT_CLASS}-form-cta-container`
const OTP_FORM_SUBMIT_SECTION_CLASS = `${ROOT_CLASS}-form-submit-container`
const FORM_INPUT_ERROR_CLASS = `${OTP_FORM_CLASS}-input-error`
const OTP_FORM_BUTTON_CLASS = `${OTP_FORM_SUBMIT_SECTION_CLASS}-submit-button`
const OTP_FORM_PASSCODE_FEEDBACK_CLASS = `${OTP_FORM_CLASS}-passcode-feedback-label`

const INITIAL_FORM_VALUES = {
  codeOne: '',
  codeTwo: '',
  codeThree: '',
  codeFour: '',
  codeFive: '',
  codeSix: ''
}

const FORM_SCHEMA = Yup.object().shape({
  codeOne: Yup.string()
    .required('Required'),
  codeTwo: Yup.string()
    .required('Required'),
  codeThree: Yup.string()
    .required('Required'),
  codeFour: Yup.string()
    .required('Required'),
  codeFive: Yup.string()
    .required('Required'),
  codeSix: Yup.string()
    .required('Required')
})

const CODE_INPUTS_NAME = ['codeOne', 'codeTwo', 'codeThree', 'codeFour', 'codeFive', 'codeSix']

const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

const OTPForm = ({ onVerifyOneTimePasscode, onVerified, isLoading, userCode, onVerifyUserValidationCode, validateChangesToken, resendCode }) => {
  const codeSixInputRef = useRef(null)
  const [showCodeInvalidError, setShowCodeInvalidError] = useState(false)
  const { code, forgotcode, tokenChangeData } = useParams()

  const copyOTPFromClipboard = useCallback(async (/* { setFieldValue, setFieldTouched } */) => {
    /* clipboard.readText().then((result) => {
      const codes = result.split('').filter((code) => code !== '\n')
      CODE_INPUTS_NAME.forEach((inputName, index) => {
        setFieldValue(inputName, codes[index])
        setFieldTouched(inputName)
      })
    }, (err) => {
      console.log(err)
    }) */
  }, [window.navigator.clipboard])

  const handleOnCodeInputChange = useCallback((setFieldValue, setFieldTouched) => (event) => {
    const { name, value } = event.target
    const valueSplited = value.split('').filter((code) => numbers.includes(code))
    if (valueSplited.length > 1) {
      CODE_INPUTS_NAME.forEach((inputName, index) => {
        setFieldValue(inputName, valueSplited[index])
        setFieldTouched(inputName)
      })
      codeSixInputRef.current.focus()
    } else {
      setFieldValue(name, value)
    }
  }, [])

  const handleOnCodeInputKeyUp = useCallback(({ key, target }, setFieldValue) => {
    if (key === 'Backspace' && target.previousSibling) {
      if (!target.value) {
        setFieldValue(target.previousSibling.name, '')
      }
      target.previousSibling.focus()
      target.previousSibling.click()
      // console.log('aqui')
      return
    }

    if (target.nextSibling && key !== 'Backspace') {
      // console.log('aqui tambien')
      target.nextSibling.focus()
      target.nextSibling.click()
    }
  }, [])

  const handleOnFormKeydown = useCallback((props) => (event) => {
    const charCode = String.fromCharCode(event.which).toLowerCase()
    // console.log(event)
    if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
      copyOTPFromClipboard(props)
    //  codeSixInputRef.current.focus()
    }
  }, [copyOTPFromClipboard, codeSixInputRef])

  const handleSendOTPCodeButtonClick = useCallback(() => {
    // console.log('handleSendOTPCodeButtonClick')
    resendCode()
  }, [])

  const handleFormSubmit = useCallback((values) => {
    const code = Object.values(values).join('')

    return new Promise((resolve, reject) => {
      if (validateChangesToken || tokenChangeData) {
        validateChanges(code)
          .then((data) => {
            onVerified(data)
            setShowCodeInvalidError(false)
            resolve(code)
          })
          .catch((error) => {
            setShowCodeInvalidError(true)
            reject(error)
          })
      } else if (!userCode) {
        onVerifyOneTimePasscode({ code })
          .then(() => {
            onVerified(code)
            setShowCodeInvalidError(false)
            resolve(code)
          })
          .catch((error) => {
            setShowCodeInvalidError(true)
            reject(error)
          })
      } else {
        onVerifyUserValidationCode(code)
          .then((data) => {
            // onVerified(code)
            onVerified(data)
            setShowCodeInvalidError(false)
            resolve(code)
          })
          .catch((error) => {
            setShowCodeInvalidError(true)
            reject(error)
          })
      }
    })
  }, [onVerified, onVerifyOneTimePasscode, onVerifyUserValidationCode])

  useEffect(() => {
    if (code && code.length > 9) {
      const codes = code.split('')
      handleFormSubmit(codes)
    }
    if (forgotcode) {
      const codes = forgotcode.split('')
      handleFormSubmit(codes)
    }
    if (tokenChangeData) {
      const codes = tokenChangeData.split('')
      handleFormSubmit(codes)
    }
  }, [code, forgotcode, tokenChangeData])

  return (
    <Container className={ROOT_CLASS}>
      <Formik
        onSubmit={handleFormSubmit}
        validationSchema={FORM_SCHEMA}
        initialValues={INITIAL_FORM_VALUES}
      >
        {({ values, errors, touched, setFieldValue, handleBlur, handleSubmit, setFieldTouched }) => (
          <Form
            className={OTP_FORM_CLASS}
            onSubmit={handleSubmit}
            onKeyDown={handleOnFormKeydown({ setFieldValue, setFieldTouched })}
          >
            <Container>
              <Form.Label>{translate('components.OTPForm.CODE_LABEL')}</Form.Label>
              <Form.Group as={Row}>
                {
                  CODE_INPUTS_NAME.map((inputName) => {
                    return (
                      <Form.Control
                        key={inputName}
                        required
                        ref={inputName === CODE_INPUTS_NAME[5] ? codeSixInputRef : null}
                        autoFocus={inputName === CODE_INPUTS_NAME[0]}
                        type="number"
                        // pattern="\d*"
                        pattern="\w*"
                        // maxLength="1"
                        name={inputName}
                        value={values[inputName]}
                        placeholder={translate('components.OTPForm.CODE_INPUT_PLACEHOLDER')}
                        className={
                          classnames({ [FORM_INPUT_ERROR_CLASS]: errors[inputName] && touched[inputName] })
                        }
                        disabled={isLoading}
                        onBlur={handleBlur}
                        onChange={handleOnCodeInputChange(setFieldValue, setFieldTouched, values)}
                        onKeyUp={(e) => handleOnCodeInputKeyUp(e, setFieldValue)}
                      />
                    )
                  })
                }
              </Form.Group>
              {showCodeInvalidError ? (
                <p className={OTP_FORM_PASSCODE_FEEDBACK_CLASS}>{translate('components.OTPForm.INVALID_PASSCODE_CAPTION')}</p>
              ) : null}
            </Container>
            <Container className={OTP_FORM_SUBMIT_SECTION_CLASS}>
              <Button
                type="submit"
                disabled={isLoading}
                className={OTP_FORM_BUTTON_CLASS}
              >
                {translate('components.OTPForm.NEXT_BUTTON_CAPTION')}
              </Button>
            </Container>
            {!userCode
              && (
              <Container className={OTP_FORM_CTA_SECTION_CLASS}>
                <Button
                  variant="link"
                  onClick={handleSendOTPCodeButtonClick}
                >
                  {translate('components.OTPForm.SEND_CODE_BUTTON_CAPTION')}
                </Button>
              </Container>
              )}
          </Form>
        )}
      </Formik>
    </Container>
  )
}

OTPForm.propTypes = {
  isLoading: PropTypes.bool,
  onVerified: PropTypes.func.isRequired,
  onVerifyOneTimePasscode: PropTypes.func.isRequired,
  onVerifyUserValidationCode: PropTypes.func.isRequired,
  resendCode: PropTypes.func,
  userCode: PropTypes.bool,
  validateChangesToken: PropTypes.bool
}

OTPForm.defaultProps = {
  isLoading: false,
  resendCode: () => false,
  validateChangesToken: false,
  userCode: false
}

export const mapStateToProps = (stateProps, ownProps) => {
  const isLoading = isLoadingSelector(stateProps)
  return {
    isLoading,
    ...ownProps
  }
}

export const mapDispatchToProps = { verifyOneTimePasscode, verifyUserValidationCode }

const mergeProps = (stateProps, { verifyOneTimePasscode, verifyUserValidationCode }, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    onVerifyOneTimePasscode: ({ code }) => verifyOneTimePasscode({ code }),
    onVerifyUserValidationCode: (code) => verifyUserValidationCode({ email_verification_token: code })
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OTPForm)
