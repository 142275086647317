import './index.scss'

import { useCallback } from 'react'
import { withRouter } from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import translate from '../../i18next'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { REACT_APP_SUPPORT_PAGE } from '../../config/config'

const ROOT_CLASS = 'dealme-not-found'
const LOGO_CLASS = `${ROOT_CLASS}-logo`
const NOT_FOUND_CTA_ROW = `${ROOT_CLASS}-cta-row`
const NOT_FOUND_CAPTION_ROW = `${ROOT_CLASS}-caption-row`

const NotFound = withRouter(({ history }) => {
  const handleOnHomeIconClick = useCallback(() => {
    history.push('/')
  }, [history])

  return (
    <Container className={ROOT_CLASS}>
      <Logo className={LOGO_CLASS} onClick={handleOnHomeIconClick} />
      <Container>
        <Row className={NOT_FOUND_CAPTION_ROW}>
          <h1>404</h1>
          <p>{translate('components.NotFound.PAGE_DOESNT_EXISTS_CAPTION')}</p>
          <p>{translate('components.NotFound.REASONS_WHY_NOT_EXISTS_CAPTION')}</p>
        </Row>
        <Row className={NOT_FOUND_CTA_ROW}>
          <a rel="noopener noreferrer" href={REACT_APP_SUPPORT_PAGE} target="_blank">
            <Button>{translate('components.NotFound.SUPPORT_BUTTON_CAPTION')}</Button>
          </a>
          <Button onClick={handleOnHomeIconClick}>
            {translate('components.NotFound.GO_TO_HOME_BUTTON_CAPTION')}
          </Button>
        </Row>
      </Container>
    </Container>
  )
})

export default NotFound
