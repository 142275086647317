/* eslint-disable react/jsx-props-no-spreading */
import axios from 'axios'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { addResponseInterceptor, apiCall, ejectResponseInterceptor } from '../api/api_client'
import { REFRESH_TOKEN } from '../api/auth.routes'
import storage from '../helpers/storage'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../store/actions/accessToken'
import { USER_STORAGE_KEY } from '../store/actions/user.types'

export const UserContext = React.createContext()

export default function UserProvider(props) {
  const [user, setUser] = useState(storage.get(USER_STORAGE_KEY))
  const [error, setError] = useState(null)

  // If the user is updated then we update the localstorage
  useEffect(async () => {
    await storage.asyncSet(USER_STORAGE_KEY, user)
    await storage.asyncSet(ACCESS_TOKEN_KEY, user ? user.token : null)
    await storage.asyncSet(REFRESH_TOKEN_KEY, user ? user.refresh_token : null)
  }, [user])

  // If an error occurs then we clear the user
  useEffect(() => {
    // console.log('error useEffect', error)
    // if (error && error.response) {
    //   console.log('error config', error.config)
    //   console.log('error response', error.response)
    //   console.log('error response.status', error.response.status)
    //   console.log('error data', error.response.data)
    // }
    if (error) setUser(null)
  }, [error])

  // To intercept when jwt has expired
  useEffect(() => {
    const interceptor = addResponseInterceptor((res) => res, (error) => {
      // console.log('interceptor error', error)
      const originalConfig = error.config
      // Reject promise if usual error. When response code is 401, try to refresh the token.
      if (error.response.status !== 401 || originalConfig.url === '/signin') {
        return Promise.reject(error)
      }
      // Eject the interceptor so it doesn't loop in case token refresh causes the 401 response
      ejectResponseInterceptor(interceptor)

      return apiCall(REFRESH_TOKEN, {
        method: 'GET',
        immediately: true,
        authorization: `bearer ${user.refresh_token}`
      }).then((response) => {
        // console.log('refresh token data', response.data)
        setUser(response.data.data)
        originalConfig.headers.Authorization = `bearer ${response.data.data.token}`
        // console.log('originalConfig', originalConfig)
        return axios(originalConfig)
      }).catch((_error) => {
        console.error('interceptor refresh token error!', _error)
        setError(_error)
        return Promise.reject(error)
      }).finally(addResponseInterceptor(interceptor))
    })
    return () => ejectResponseInterceptor(interceptor)
  })

  const logout = useCallback(() => {
    setUser(null)
  }, [])

  const options = useMemo(
    () => ({
      isLogged: user !== null,
      userData: user,
      setUserData: (data) => setUser(data),
      error,
      setError,
      // login,
      logout,
    }),
    [user, error]
  )

  return <UserContext.Provider value={options} {...props} />
}
