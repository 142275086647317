import axios from 'axios'
// userData?.token
export const markNotificationasReaded = (token, id) => {
  const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`

  axios.defaults.baseURL = API_URL
  axios.defaults.headers['Content-Type'] = 'application/json'
  axios.defaults.headers.Authorization = `bearer ${token}`
  axios.create().post(
    'user/notification/read',
    {
      id
    }
  )
    .then(() => {
      // setIsFormSubmitted(true)
    })
    .catch((error) => {
      console.log(error)
    })
}
