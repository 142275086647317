import './index.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'

import imageProfile from '../../../assets/Profile/profile_empty.png'

const ImageAvatar = (props) => {
  const { image, width, height, borderRadius, scale, rotate, allowZoomOut, position, saveImage, closeDialog } = props

  const defaultState = {
    image,
    allowZoomOut,
    position,
    scale,
    rotate,
    borderRadius,
    // preview: preview,
    width,
    height,
    editor: null,
    // color:color || [255, 255, 255, 0.6] // RGBA
  }
  const [avatarEdit, setAvatarEdit] = useState(defaultState)

  const setEditorRef = (editor) => {
    if (editor) {
      avatarEdit.editor = editor
    }
  }

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value)
    setAvatarEdit({ ...avatarEdit, scale })
  }

  return (
    <div className="dealme-avatar-container">
      {(
        avatarEdit.image ? (
          <>
            <AvatarEditor
              ref={setEditorRef}
              image={avatarEdit.image && avatarEdit.image.data ? avatarEdit.image.data : avatarEdit.image}
              // position={avatarEdit.position}
              width={avatarEdit.width}
              height={avatarEdit.height}
              borderRadius={200}
              // borderRadius={avatarEdit.borderRadius}
              scale={parseFloat(avatarEdit.scale)}
              rotate={parseFloat(avatarEdit.rotate)}
              // preview={avatarEdit.preview}
            />
            <br />
            <div className="avatar-controls mt-2">
              <span>Zoom: </span>
              <span>
                <input
                  name="scale"
                  type="range"
                  onChange={handleScale}
                  min={avatarEdit.allowZoomOut ? '0.1' : '1'}
                  max="3"
                  step="0.01"
                  defaultValue="1"
                />
              </span>
            </div>
          </>
        ) : <img src={imageProfile} alt="NotFound" />
      )}
      <div>
        <button
          type="button"
          className="mt-3"
          onClick={() => {
            // setEditImage(img)
            // setUserData({ ...userData, image: editImage })
            if (avatarEdit.image) {
              const canvasScaledWeb = avatarEdit.editor ? avatarEdit.editor.getImageScaledToCanvas().toDataURL() : null
              saveImage(canvasScaledWeb)
            }
            closeDialog()
          }}
        >
          {/* {translate('components.Common.SAVE')} */}Save
        </button>
      </div>
    </div>

  )
}

export default ImageAvatar

ImageAvatar.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scale: PropTypes.number,
  rotate: PropTypes.number,
  allowZoomOut: PropTypes.bool,
  saveImage: PropTypes.func.isRequired,
  closeDialog: PropTypes.func,
  position: PropTypes.shape({ x: PropTypes.number.isRequired, y: PropTypes.number.isRequired })
}

ImageAvatar.defaultProps = {
  image: null,
  closeDialog: null,
  width: 250,
  height: 250,
  borderRadius: 250,
  scale: 1,
  rotate: 0,
  allowZoomOut: true,
  position: { x: 0.5, y: 0.5 },
}
