import './index.scss'

import PropTypes from 'prop-types'
import translate from '../../../i18next'

const ROOT_CLASS = 'dealme-modal'
const OVERLAY_CLASS = `${ROOT_CLASS}-overlay`
const OVERLAY_HIDDEN_CLASS = `${ROOT_CLASS}-overlay-hidden`
const CONTAINER_MODAL_CLASS = `${ROOT_CLASS}-container`
const CONTAINER_MODAL_BODY_CLASS = `${ROOT_CLASS}-container-body`
const CONTAINER_MODAL_CLASS_HIDDEN = `${ROOT_CLASS}-container-hidden`
const CONTAINER_MODAL_TITLE_WRAPPER_CLASS = `${ROOT_CLASS}-container-title-wrapper`
const CLOSE_BUTTON_CLASS = `${ROOT_CLASS}-close-button`
const FOOTER_CLASS = `${ROOT_CLASS}-footer`
const CANCEL_BUTTON_CLASS = `${ROOT_CLASS}-cancel-button`
const CONFIRM_BUTTON_CLASS = `${ROOT_CLASS}-confirm-button`

export default function ModalDialog({ title, children, show, setShow, onHide, setActionOk }) {
  return (
    <>
      <div className={show ? OVERLAY_CLASS : OVERLAY_HIDDEN_CLASS} />
      <div className={show ? CONTAINER_MODAL_CLASS : CONTAINER_MODAL_CLASS_HIDDEN}>
        <div className={CONTAINER_MODAL_TITLE_WRAPPER_CLASS}>
          <h2>{title}</h2>
          <button
            type="button"
            className={CLOSE_BUTTON_CLASS}
            onClick={() => {
              setShow(false)
              if (onHide) onHide()
            }}
          >
            <img src="/close-button.png" alt="" />
          </button>
        </div>
        <div className={CONTAINER_MODAL_BODY_CLASS}>
          {children}
        </div>
        {(setActionOk
        && (
          <div className={`${FOOTER_CLASS} text-center`}>
            <button type="button" className={CANCEL_BUTTON_CLASS} onClick={() => { setShow(false); if (onHide) onHide() }}>
              {translate('components.Common.CANCEL')}
            </button>
            <button type="button" className={CONFIRM_BUTTON_CLASS} onClick={() => { setActionOk(); setShow(false) }}>
              {translate('components.Common.CONFIRM')}
            </button>
          </div>
        )
      )}
      </div>
    </>
  )
}

ModalDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  // size: PropTypes.string,
  onHide: PropTypes.func,
  setActionOk: PropTypes.func
}

ModalDialog.defaultProps = {
  title: '',
  children: '',
  // size: 'xl',
  setActionOk: null,
  onHide: null
}
