import './index.scss'

import { withRouter, useParams } from 'react-router-dom'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as Yup from 'yup'

import classnames from 'classnames'
import { toast } from 'react-toastify'
import { Formik, ErrorMessage } from 'formik'

import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import translate from '../../i18next'
import { signIn } from '../../store/actions'
import { isLoadingSelector } from '../../store/selectors/userSelector'

import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg'
import useUser from '../../hooks/useUser'
import SpinnerCustom from '../common/SpinnerCustom'
import storage from '../../helpers/storage'
import { USER_STORAGE_KEY } from '../../store/actions/user.types'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../../store/actions/accessToken'

const ROOT_CLASS = 'dealme-signin'
const LOGO_CLASS = `${ROOT_CLASS}-logo`
const CAPTION_CONTAINER_CLASS = `${ROOT_CLASS}-caption-container`
const FORM_CONTAINER_CLASS = `${ROOT_CLASS}-form-container`
const SIGNIN_FORM_CLASS = `${ROOT_CLASS}-form`
const SIGNIN_FORM_SUBMIT_SECTION_CLASS = `${ROOT_CLASS}-form-submit-container`
const FORM_INPUT_ERROR_MESSAGE_CLASS = `${SIGNIN_FORM_CLASS}-input-error-message`
const FORM_INPUT_ERROR_CLASS = `${SIGNIN_FORM_CLASS}-input-error`
const SIGNIN_BUTTON_CLASS = `${SIGNIN_FORM_SUBMIT_SECTION_CLASS}-submit-button`
const SIGNIN_FORM_CTA_CONTAINER_CLASS = `${SIGNIN_FORM_SUBMIT_SECTION_CLASS}-cta-container`

const LOGIN_ERROR_TOAST_AUTOCLOSE = 6 * 1000 // 6 seconds

const INITIAL_FORM_VALUES = {
  email: '',
  password: ''
}

const FORM_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email') // TODO, get translate from designer
    .required('Required'), // TODO, get translate from designer
  password: Yup.string()
    .required('Required'), // TODO, get translate from designer
})

const SignIn = withRouter(({ history, onSignIn, isLoading }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [useGo, setUseGo] = useState(true)
  const { error } = useUser()
  const { email } = useParams()

  useEffect(() => {
    if (error && !isLoadingData) {
      console.error('error', error)
      toast.error(translate('components.SignIn.SESSION_END'), { autoClose: LOGIN_ERROR_TOAST_AUTOCLOSE, toastId: 'SESSION_END' })
    }
  }, [error])

  const handleOnHomeIconClick = useCallback(() => {
    history.push('/')
  }, [history])

  const handlePasswordVisibleIconClick = useCallback(() => {
    setPasswordVisible(!isPasswordVisible)
  }, [isPasswordVisible])

  const passwordFieldType = useMemo(() => {
    return isPasswordVisible ? 'text' : 'password'
  }, [isPasswordVisible])

  const renderPasswordVisibilityIcon = useCallback(() => {
    return isPasswordVisible ? (
      <EyeSlashIcon onClick={handlePasswordVisibleIconClick} />
    ) : (
      <EyeIcon onClick={handlePasswordVisibleIconClick} />
    )
  }, [isPasswordVisible, handlePasswordVisibleIconClick])

  const handleOnFormSubmit = useCallback(async ({ email, password }) => {
    setIsLoadingData(true)
    onSignIn({ email, password })
      .then(async (res) => {
        await storage.asyncSet(USER_STORAGE_KEY, res.data)
        await storage.asyncSet(ACCESS_TOKEN_KEY, res.data.token)
        await storage.asyncSet(REFRESH_TOKEN_KEY, res.data.refresh_token)
        // setUserData(res.data)
        if (res.data && !res.data.onboarding_done && res.data.user_type === 'influencer') {
          history.push('/getstarted')
        } else {
          history.push('/home')
        }
        if (useGo) history.go() // fix redirection
      })
      .catch(() => {
        toast.error(translate('components.SignIn.EMAIL_OR_PASSWORD_INCORRECT'), { autoClose: LOGIN_ERROR_TOAST_AUTOCLOSE })
      })
      .finally(() => setIsLoadingData(false))
  }, [onSignIn, toast, history])

  const handleForgotPasswordButtonClick = useCallback(() => {
    history.push('/forgot-password')
  }, [history])

  const handleSignUpButtonClick = useCallback(() => {
    history.push('/signup')
  }, [history])

  useEffect(async () => {
    if (email) {
      const user = await storage.asyncGet(USER_STORAGE_KEY)
      if (user && user.password && email === user.email) {
        setIsLoadingData(true)
        setUseGo(false)
        handleOnFormSubmit({ email, password: user.password })
      }
    }
  }, [email])

  // useEffect(() => {
  //   if (history.location.pathname !== '/signin' && !userData) {
  //     history.push('/signin')
  //   }
  // }, [])

  return (
    <Container className={ROOT_CLASS}>
      {isLoadingData && <SpinnerCustom />}
      <Logo
        className={LOGO_CLASS}
        onClick={handleOnHomeIconClick}
      />
      <Container className={CAPTION_CONTAINER_CLASS}>
        <p>{translate('components.SignIn.SIGNIN_CAPTION')}</p>
      </Container>
      <Container className={FORM_CONTAINER_CLASS}>
        <Formik
          onSubmit={handleOnFormSubmit}
          validationSchema={FORM_SCHEMA}
          validateOnBlur={false}
          initialValues={email ? { ...INITIAL_FORM_VALUES, email } : INITIAL_FORM_VALUES}
        >
          {({ values, errors, touched, isValid, handleChange, handleBlur, handleSubmit }) => (
            <Form noValidate className={SIGNIN_FORM_CLASS} onSubmit={handleSubmit}>
              <Container>
                <Form.Group as={Row}>
                  <Form.Label>{translate('components.Signup.EMAIL_LABEL')}</Form.Label>
                  <Form.Control
                    required
                    autoFocus
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={values.email}
                    placeholder={translate('components.Signup.EMAIL_INPUT_PLACEHOLDER')}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.email && touched.email })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="email" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label>{translate('components.Signup.PASSWORD_LABEL')}</Form.Label>
                  <InputGroup>
                    <FormControl
                      required
                      name="password"
                      type={passwordFieldType}
                      value={values.password}
                      placeholder="*********"
                      className={
                        classnames({ [FORM_INPUT_ERROR_CLASS]: errors.password && touched.password })
                      }
                      disabled={isLoading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <InputGroup.Append> {renderPasswordVisibilityIcon()} </InputGroup.Append>
                  </InputGroup>
                  <ErrorMessage name="password" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                </Form.Group>
              </Container>
              <Container className={SIGNIN_FORM_SUBMIT_SECTION_CLASS}>
                <Button
                  type="submit"
                  disabled={isLoading || !isValid}
                  className={SIGNIN_BUTTON_CLASS}
                >
                  {translate('components.SignIn.SIGNIN_CAPTION')}
                </Button>
                <Container className={SIGNIN_FORM_CTA_CONTAINER_CLASS}>
                  <Button
                    variant="link"
                    onClick={handleForgotPasswordButtonClick}
                  >
                    {translate('components.SignIn.FORGOT_LINK_CAPTION')}
                  </Button>
                  <Button
                    variant="link"
                    onClick={handleSignUpButtonClick}
                  >
                    {translate('components.SignIn.SIGNUP_BUTTON_CAPTION')}
                  </Button>
                </Container>
              </Container>
            </Form>
          )}
        </Formik>
      </Container>
    </Container>
  )
})

SignIn.propTypes = {
  history: PropTypes.shape({}).isRequired,
  onSignIn: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

SignIn.defaultProps = {
  isLoading: false
}

export const mapStateToProps = (stateProps, ownProps) => {
  const isLoading = isLoadingSelector(stateProps)
  return {
    isLoading,
    ...ownProps
  }
}

export const mapDispatchToProps = { signIn }

const mergeProps = (stateProps, { signIn }, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    onSignIn: ({ email, password }) => signIn({ email, password })
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SignIn)
