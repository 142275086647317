import { apiCall } from './api_client'
import { ADMIN_INVITATION, ADMIN_LIST_USERS, USER_DELETE, ADMIN_INVITATION_ACCEPT } from './auth.routes'

export const getUsers = (immediately = true) => apiCall(ADMIN_LIST_USERS, {
  method: 'GET',
  immediately,
})

export const getInvitationAdmin = (token) => apiCall(`${ADMIN_INVITATION}/${token}`, {
  method: 'GET',
  immediately: true,
})

export const addAdmin = (data) => apiCall(ADMIN_INVITATION, {
  method: 'POST',
  immediately: true,
  data,
})

export const acceptInvitationAdmin = (data) => {
  return apiCall(`${ADMIN_INVITATION_ACCEPT}/${data.token}`, {
    method: 'POST',
    immediately: true,
    data
  })
}

export const deleteUser = (id) => {
  return apiCall(`${USER_DELETE}/${id}`, {
    method: 'DELETE',
    immediately: true,
  })
}
