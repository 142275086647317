import storage from '../../helpers/storage'
import { USER_STORAGE_KEY } from './user.types'

export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN'
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN'
export const READ_ACCESS_TOKEN = 'READ_ACCESS_TOKEN'
export const READ_ACCESS_TOKEN_FAIL = 'READ_ACCESS_TOKEN_FAIL'
// export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
// export const SET_ACCESS_TOKEN_FAIL = 'SET_ACCESS_TOKEN_FAIL'

export const readAccessToken = () => {
  return (dispatch) => {
    dispatch({ type: READ_ACCESS_TOKEN })

    return new Promise((resolve, reject) => {
      try {
        const accessToken = storage.get(USER_STORAGE_KEY).token
        dispatch({ type: READ_ACCESS_TOKEN, accessToken })
        return resolve(accessToken)
      } catch {
        dispatch({ type: READ_ACCESS_TOKEN_FAIL })
        return reject()
      }
    })
  }
}

// export const setAccessToken = (payload) => {
//   console.log('setAccessToken', payload)
//   return (dispatch) => {
//     dispatch({ type: SET_ACCESS_TOKEN })

//     return new Promise((resolve, reject) => {
//       try {
//         dispatch({ type: SET_ACCESS_TOKEN, payload })
//         return resolve()
//       } catch {
//         dispatch({ type: SET_ACCESS_TOKEN_FAIL })
//         return reject()
//       }
//     })
//   }
// }
