import './index.scss'

import { useCallback } from 'react'
import { withRouter } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import translate from '../../i18next'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'

const ROOT_CLASS = 'dealme-terms-of-use'
const LOGO_CLASS = `${ROOT_CLASS}-logo`
const TERMS_OF_USE_CAPTION = `${ROOT_CLASS}-caption-row`
const TERMS_OF_USE_CONTENT = `${ROOT_CLASS}-content-row`

const TermsOfUse = withRouter(({ history }) => {
  const handleOnHomeIconClick = useCallback(() => {
    history.push('/')
  }, [history])

  return (
    <Container className={ROOT_CLASS}>
      <Logo className={LOGO_CLASS} onClick={handleOnHomeIconClick} />
      <Container>
        <Row className={TERMS_OF_USE_CAPTION}>
          <h1>{translate('components.TermsOfUse.TERMS_OF_USE_CAPTION')}</h1>
        </Row>
        <Row className={TERMS_OF_USE_CONTENT}>
          <h4>1. Section title</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
          </p>
          <h4>1. Section title</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultricies ultrices posuere.
          </p>
        </Row>
      </Container>
    </Container>
  )
})

export default TermsOfUse
