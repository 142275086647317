import './GetStarted.scss'

import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Container from 'react-bootstrap/Container'
import translate from '../../i18next'
import useUser from '../../hooks/useUser'
import useWindowSize from '../../hooks/useWindowSize'
import ButtonFile from '../common/ButtonFile'
import ImageAvatar from '../common/ImageAvatar'
import ModalDialog from '../common/ModalDialog'
import { Footer } from '../Landing/Footer/Footer'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right-solid.svg'
import imageProfile from '../../assets/Profile/profile_empty.png'
import StepperDots from '../common/StepperDots'
import { updateData, uploadImage } from '../../api/user'
import SpinnerCustom from '../common/SpinnerCustom'

const copy = require('clipboard-copy')

export const GetStarted = () => {
  const history = useHistory()
  const { userData, setUserData } = useUser()
  const size = useWindowSize()
  // const [checked, setChecked] = useState(userData.avatar_image ? 1 : 0)
  const [checked, setChecked] = useState(0)
  const [showEditImage, setShowEditImage] = useState(false)
  const [editImage, setEditImage] = useState(null)
  const [step, setStep] = useState({ viewAll: !!size.width > 999, id: 0 })
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const [isLoadingData, setIsLoadingData] = useState(false)

  useEffect(() => {
    if (size.width > 989 && !step.viewAll) {
      setStep({ ...step, viewAll: true })
    } else if (size.width < 990 && step.viewAll) {
      setStep({ ...step, viewAll: false })
    }
  }, [size.width])

  const copyText = () => {
    // navigator.clipboard.writeText(useUrlRef.current?.innerText)
    // await navigator.clipboard.writeText(`${window.location.origin}/s/${userData.submission_form_slug}`)
    copy(`${window.location.origin}/s/${userData.submission_form_slug}`)
    toast.success(translate('components.GetStarted.LINK_COPIED'))
    setChecked(1)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const saveAvatar = (avatar) => {
    if (avatar) {
      setIsLoadingData(true)
      setChecked(2)
      setUserData({ ...userData, avatar_image: avatar, onboarding_done: 1 })
      const data = {
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        phone: userData.phone,
        country_code: userData.country_code,
        onboarding_done: 1,
        avatar_image: avatar
      }
      // updateData({ avatar_image: avatar })
      updateData(data)
        .then(() => {
          toast.success(translate('components.GetStarted.SAVE_PROFILE_AVATAR'))
        })
        .catch(() => {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
        })
        .finally(() => setIsLoadingData(false))
    }
  }

  const handleNewImage = (file) => {
    // setUserData({ ...userData, image: URL.createObjectURL(file) })
    setIsLoadingData(true)
    setEditImage(file)
    setShowEditImage(true)
    convertBase64(file)
      .then((image64) => {
        // console.log('conver to base64', image64)
        // setUserData({ ...userData, image: image64 })
        uploadImage({ image: image64 })
      })
      .then(() => {
        toast.success(translate('components.GetStarted.SAVE_PROFILE_PICTURE_BUTTON'))
      })
      .catch(() => {
        toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
      })
      .finally(() => setIsLoadingData(false))
    if (checked === 0) setChecked(1)
  }

  const handleHomeButtonClick = useCallback(() => {
    setChecked(3)
    if (!editImage) {
      updateData({
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        phone: userData.phone,
        country_code: userData.country_code,
        onboarding_done: 1
      })
      // setUserData({ ...userData, onboarding_done: 1 })
    }
    history.push('/home')
  }, [history])

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX)
  }
  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }
  const handleTouchEnd = () => {
    if (touchEnd && touchStart - touchEnd > 100 && step.id < 2) {
      setStep({ ...step, id: step.id + 1 })
    }
    if (touchEnd && touchStart - touchEnd < -100 && step.id > 0) {
      setStep({ ...step, id: step.id - 1 })
    }
    setTouchStart(0)
    setTouchEnd(0)
  }

  return (
    <>
      {isLoadingData && <SpinnerCustom />}
      <Container className="gstart-container">
        <div className="gstart-logo">
          <Logo alt={translate('components.Landing.Header.LOGO_ALT')} />
        </div>
        <p className="gstart-title">
          <strong className="gstart-title">
            {translate('components.GetStarted.HELLO')} {userData.firstname}!
          </strong> {translate('components.GetStarted.WELCOME')}
        </p>
        <div
          className="gstart-checkcards"
          onTouchMove={(e) => handleTouchMove(e)}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={handleTouchEnd}
          style={{ paddingRight: touchEnd && `${touchStart - touchEnd}px`, paddingLeft: touchEnd && `${touchEnd - touchStart}px` }}
        >
          { (step.viewAll || step.id === 0)
          && (
          <div className="gstart-card">
            <div className={checked >= 1 ? 'gstart-card-ciclecheck' : 'gstart-card-cicleuncheck'}>
              {checked >= 1 ? <p className="textcheck">&#10003;</p> : <p className="textcheck">1</p>}
            </div>
            <div className="gstart-card-content">
              <video className="gstart-card-content-video" controls controlsList="nofullscreen nodownload" preload="none">
                <source src="https://s3.eu-central-1.amazonaws.com/pipe.public.content/short.mp4" type="video/mp4" />
                <track
                  src="captions_en.vtt"
                  kind="captions"
                  srcLang="en"
                  label="english_captions"
                />
              </video>
              <p className="gstart-card-text-title">
                {translate('components.GetStarted.LINK_COPY')}
              </p>
              <p className="gstart-card-text">
                {translate('components.GetStarted.LINK_COPY_DESC')}
              </p>
            </div>
            <div className="gstart-card-copy-url">
              {/* <p ref={useUrlRef} value={useUrlRef.current}>{useUrlRef.current}</p> */}
              <p>{window.location.origin}/s/{userData.submission_form_slug}</p>
              <button type="button" onClick={copyText}>{translate('components.Common.COPY')}</button>
            </div>
          </div>
          )}
          { (step.viewAll || step.id === 1)
          && (
          <div className="gstart-card">
            <div className={checked >= 2 ? 'gstart-card-ciclecheck' : 'gstart-card-cicleuncheck'}>
              {checked >= 2 ? <p className="textcheck">&#10003;</p> : <p className="textcheck">2</p>}
            </div>
            <div className="gstart-card-content">
              <img src={userData.avatar_image ? userData.avatar_image : imageProfile} alt="NotFound" />
              {/* <ImageAvatar image={userData.image} width="12rem" /> */}
              <p className="gstart-card-text-title">
                {translate('components.GetStarted.ADD_PROFILE_PICTURE')}
              </p>
              <p className="gstart-card-text">
                {translate('components.GetStarted.ADD_PROFILE_PICTURE_DESC')}
              </p>
            </div>
            <ButtonFile
              label={!userData.avatar_image ? translate('components.GetStarted.ADD_PROFILE_PICTURE_BUTTON') : translate('components.GetStarted.REPLACE_PROFILE_PICTURE_BUTTON')}
              setValue={handleNewImage}
            />
            {/* <button type="button" onClick={() => setShowEditImage(true)}>Add Picture</button> */}
            {/* <button type="button" onClick={() => setUserData({ ...userData, image: null })}>Remove Picture</button> */}
          </div>
          )}
          { (step.viewAll || step.id === 2)
          && (
          <div className="gstart-card">
            <div className={checked === 3 ? 'gstart-card-ciclecheck' : 'gstart-card-cicleuncheck'}>
              {checked === 3 ? <p className="textcheck">&#10003;</p> : <p className="textcheck">3</p>}
            </div>
            <div className="gstart-card-getstarted" role="button" onClick={handleHomeButtonClick} onKeyDown={handleHomeButtonClick} tabIndex={0}>
              <p className="gstart-card-getstarted-title">{translate('components.Common.GET_STARTED')}</p>
              {/* <p className="gstart-card-getstarted-arrow">&#129122;</p> */}
              <ArrowRight className="gstart-card-getstarted-arrow" alt="&#129122;" />
            </div>
          </div>
          )}
        </div>
      </Container>
      {!step.viewAll
      && (
      <div className="mt-3">
        <StepperDots onSelect={(id) => setStep({ ...step, id })} activeStep={step.id} stepsNumber={3} />
      </div>
      )}
      <div className="gstart-footer">
        <Footer />
      </div>

      {(showEditImage
        && (
        <ModalDialog show={showEditImage} setShow={setShowEditImage} title={translate('components.GetStarted.EDIT_PROFILE_AVATAR')}>
          <ImageAvatar
            image={editImage}
            saveImage={saveAvatar}
            closeDialog={() => setShowEditImage(false)}
          />
        </ModalDialog>
        )
      )}
    </>
  )
}
