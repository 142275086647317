/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import Container from 'react-bootstrap/Container'
import './NotificationCard.scss'
import imageProfile from '../../assets/Profile/profile_empty.png'

const ROOT_CLASS = 'dealme-notification-card'
const NOTIFICATION_TEXT_CLASS = `${ROOT_CLASS}-text`
const NOTIFICATION_BODY_CLASS = `${ROOT_CLASS}-body`
const BUTTONS_AND_TIME_CONTAINER_CLASS = `${ROOT_CLASS}-buttons-and-time-container`
const BUTTON_CLASS = `${ROOT_CLASS}-button`
const TIME_CLASS = `${ROOT_CLASS}-time`
const BUTTONS_CONTAINER_CLASS = `${ROOT_CLASS}-buttons-container`
const PROFILE_IMAGE_CLASS = `${ROOT_CLASS}-profile-image`

export default function NotificationCard({ image, text, time, read, markAsReaded, markAsUnReaded, token, id, onDelete }) {
  const now = new Date()
  const date = new Date(time)
  const isToday = now.getFullYear() === date.getFullYear()
      && now.getMonth() === date.getMonth()
      && now.getDate() === date.getDate()

  const isYesterday = now.getFullYear() === date.getFullYear()
      && now.getMonth() === date.getMonth()
      && now.getDate() === date.getDate() - 1

  const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
  const min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const timeText = `at ${hours}:${min} ${date.getHours() > 12 ? 'pm' : 'am'}`
  return (
    <Container className={ROOT_CLASS}>
      <img className={PROFILE_IMAGE_CLASS} src={image ?? imageProfile} alt="profile" />
      <div className={NOTIFICATION_BODY_CLASS}>
        <p className={NOTIFICATION_TEXT_CLASS}>{text}</p>
        <div className={BUTTONS_AND_TIME_CONTAINER_CLASS}>
          <div className={BUTTONS_CONTAINER_CLASS}>
            <div
              className={BUTTON_CLASS}
              onClick={() => (read
                ? markAsUnReaded(token, id)
                : markAsReaded(token, id))}
            >{read ? 'MARK AS UNREAD' : 'MARK AS READ'}
            </div>
            <div className={BUTTON_CLASS} onClick={() => onDelete(token, id)}>DELETE</div>
          </div>
          {isToday
          && <div className={TIME_CLASS}>Today {timeText}</div>}
          {isYesterday
          && (
          <div className={TIME_CLASS}>
            Yesterday {timeText}
          </div>
          )}
          {!isYesterday && !isToday
           && (
           <div className={TIME_CLASS}>{new Date(time).toDateString()}</div>
           )}
        </div>
      </div>
    </Container>
  )
}
