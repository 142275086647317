import './index.scss'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { deleteInfluencerManager } from '../../api/influencer'
import translate from '../../i18next'
import ModalDialog from '../common/ModalDialog'
import SpinnerCustom from '../common/SpinnerCustom'
import useWindowSize from '../../hooks/useWindowSize'
import MobileCard from '../common/MobileCard'

import { ReactComponent as Sort } from '../../assets/icons/sort.svg'
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-solid.svg'

const ROOT_CLASS = 'dealme-manager-list'
const TITLE = `${ROOT_CLASS}-title`
const TABLE = `${ROOT_CLASS}-table`
const ROW = `${ROOT_CLASS}-row`
const EXPIRED = `${ROOT_CLASS}-status-0`
const ACCEPTED = `${ROOT_CLASS}-status-1`
const PENDING = `${ROOT_CLASS}-status-2`
const CLOSE_BUTTON_CLASS = `${ROOT_CLASS}-close-button`

const ManagerList = ({ managers, setManagers, isLoading }) => {
  const size = useWindowSize()
  const [sortCriteria, setSortCriteria] = useState(false)
  const [double, setDouble] = useState(true)
  const [deleteItem, setDeleteItem] = useState(null)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [isLoadingDelete, setLoadingDelete] = useState(false)

  const handleRemove = () => {
    if (deleteItem) {
      setLoadingDelete(true)
      deleteInfluencerManager(deleteItem.invitation_token)
        .then((res) => {
          if (res.success) {
            toast.success(translate('components.AccountSettings.DELETE_MANAGER'))
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          }
          setManagers([...managers.filter((m) => m.invitation_token !== deleteItem.invitation_token)])
        })
        .catch((error) => {
          if (error.response && error.response.status !== 401 && error.response.data.message) {
            // setError(error.response)
            // } else if (error.response && error.response.data.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
            console.error('error delete item', error)
          }
        })
        .finally(() => setLoadingDelete(false))
    }
  }

  const sortBy = (criteria) => {
    if (sortCriteria === criteria) {
      setDouble(!double)
      const newListOrder = [...managers].reverse()
      setManagers(newListOrder)
    } else {
      setDouble(false)
      setSortCriteria(criteria)
      setManagers(managers.sort((a, b) => {
        const varA = Object.prototype.toString.call(a[criteria]) === '[object String]' ? a[criteria].toLowerCase() : a[criteria]
        const varB = Object.prototype.toString.call(b[criteria]) === '[object String]' ? b[criteria].toLowerCase() : b[criteria]
        if (varA < varB) return -1
        return (varA > varB) ? 1 : 0
      }))
    }
  }

  useEffect(() => {
    if (managers.length > 0 && !sortCriteria) {
      setDouble(false)
      setSortCriteria('firstname')
      setManagers(managers.sort((a, b) => {
        if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) return -1
        return (a.firstname.toLowerCase() > b.firstname.toLowerCase()) ? 1 : 0
      }))
    }
  }, [managers, sortCriteria])

  const getStatusText = (state) => {
    if (state === 0) return 'EXPIRED'
    return state === 1 ? 'ACCEPTED' : 'PENDING'
  }

  const getStatusClass = (state) => {
    if (state === 0) return EXPIRED
    return state === 1 ? ACCEPTED : PENDING
  }

  const getStatusColor = (state) => {
    if (state === 0) return { 'status-0': true }
    if (state === 3) return { 'status-3': true }
    return state === 1 ? { 'status-1': true } : { 'status-2': true }
  }

  return (
    <>
      {isLoadingDelete && <SpinnerCustom />}
      <div className={ROOT_CLASS}>
        <div className={TITLE}>{translate('components.AccountSettings.MANAGER_LIST_TITTLE')}</div>
        {!isLoading
          ? (
            <>
              <div>
                {managers.length > 0 && (size.width > 991
                  ? (
                    <table className={`${TABLE} d-none d-lg-table px-2`}>
                      <tbody>
                        <tr>
                          <th id="firstname" onClick={() => sortBy('firstname')}>
                            FIRST NAME {sortCriteria === 'firstname'
                            ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                            : <Sort className="ml-1 mb-1" />}
                          </th>
                          <th id="lastname" onClick={() => sortBy('lastname')}>
                            LAST NAME {sortCriteria === 'lastname'
                            ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                            : <Sort className="ml-1 mb-1" />}
                          </th>
                          <th id="email" onClick={() => sortBy('email')}>
                            EMAIL {sortCriteria === 'email'
                            ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                            : <Sort className="ml-1 mb-1" />}
                          </th>
                          <th id="company" onClick={() => sortBy('company')}>
                            COMPANY {sortCriteria === 'company'
                            ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                            : <Sort className="ml-1 mb-1" />}
                          </th>
                          <th id="state" onClick={() => sortBy('state')}>
                            STATUS {sortCriteria === 'state'
                            ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} className="ml-1 mb-1" />
                            : <Sort className="ml-1 mb-1" />}
                          </th>
                          <th id="delete"> </th>
                        </tr>
                        {managers.map((manager) => (
                          <tr key={manager.invitation_token} className={ROW}>
                            <td>{manager?.firstname}</td>
                            <td>{manager?.lastname}</td>
                            <td>{manager?.email}</td>
                            <td>{manager?.company}</td>
                            <td>
                              <div className={getStatusClass(manager.state)}>
                                {getStatusText(manager.state)}
                              </div>
                            </td>
                            <td className="text-center">
                              <button
                                type="button"
                                className={CLOSE_BUTTON_CLASS}
                                onClick={() => { setDeleteItem(manager); setOpenConfirmDelete(true) }}
                              >
                                <img src={manager.state === 1 ? '/delete2.png' : '/close-button.png'} alt="X" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                  : (
                    <div className="d-block d-lg-none overflow-hidden p-2 mobile-home">
                      {managers.map((item) => (
                        <MobileCard
                          item={{
                            name: `${item.firstname} ${item.lastname}`,
                            email: item.email,
                            company: item.company,
                            status: getStatusText(item.state)
                          }}
                          statusColor={getStatusColor(item.state)}
                          id={item.email}
                          itemFunction={{
                            name: 'Delete',
                            action: () => { setDeleteItem(item); setOpenConfirmDelete(true) } }}
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
              <div className="pb-4">
                {managers.length === 0 && <div className="d-flex justify-content-center text-center">{translate('components.AccountSettings.MESSAGE_EMPTY_MANAGER')}</div>}
              </div>
            </>
          )
          : <SpinnerCustom />}
      </div>

      {(openConfirmDelete
        && (
        <ModalDialog show={openConfirmDelete} setShow={setOpenConfirmDelete} title="" setActionOk={handleRemove}>
          {translate('components.AccountSettings.DELETE_MANAGER_CONFIRM')}
        </ModalDialog>
        )
      )}
    </>
  )
}

export default ManagerList

ManagerList.propTypes = {
  managers: PropTypes.instanceOf(Array),
  setManagers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
}

ManagerList.defaultProps = {
  managers: [],
  isLoading: false
}
