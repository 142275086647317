import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg'
import { ReactComponent as Intagram } from '../../../assets/icons/instagram.svg'
import { ReactComponent as Facebook } from '../../../assets/icons/facebook.svg'
import { ReactComponent as Twitter } from '../../../assets/icons/twitter.svg'
import { ReactComponent as FacebookMobile } from '../../../assets/icons/facebook-mobile.svg'
import { ReactComponent as TwitterMobile } from '../../../assets/icons/twitter-mobile.svg'
import { ReactComponent as IntagramMobile } from '../../../assets/icons/instagram-mobile.svg'
import translate from '../../../i18next'
import './footer.scss'

export const Footer = () => {
  return (
    <>
      <div className="footer-container">
        <div className="footer-container-logo m-4">
          <Logo
            className="footer-logo"
            alt={translate('components.Landing.Header.LOGO_ALT')}
          />
          <p className="d-none d-lg-block">&copy; 2021 Deal.me. All rights reserved</p>
        </div>
        <div className="footer-terms-policy">
          <div className="d-none flex-row-reverse d-lg-flex align-items-center mb-4 mx-2">
            <Facebook />
            <Twitter className="mx-2" />
            <Intagram />
          </div>
          <div className="d-flex flex-row d-lg-none align-items-center mb-4 mx-4">
            <FacebookMobile />
            <TwitterMobile className="mx-2" />
            <IntagramMobile />
          </div>
          <Link to="/terms-of-use" className="ml-4">TERMS OF USE</Link>
          <Link to="/privacy-policy">PRIVACY POLICY</Link>
          <div className="footer-container-logo mt-2 ml-4">
            <p className="d-block d-lg-none">&copy; 2021 Deal.me. All rights reserved</p>
          </div>
        </div>
      </div>
    </>
  )
}
