/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import './story.scss'

export const Story = ({ stories, style }) => {
  return (
    <>
      <div className="story-container" style={style}>
        <img src={stories.image} alt="NotFound" className="influencer mobile d-block d-lg-none" />
        <div className="container-text">
          <p className="story-text px-2">{stories.text}</p>
          <p className="story-name">{stories.name}</p>
          <p className="story-role">{stories.role}</p>
        </div>
        <img src={stories.image} alt="NotFound" className="influencer desktop d-none d-lg-inline" />
      </div>
    </>
  )
}

Story.propTypes = {
  stories: PropTypes.shape({
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}
