/* Authentication */

export const USER_STORAGE_KEY = 'USER_DEALME'
export const SIGN_IN = 'SIGN_IN'
export const SIGNED_IN = 'SIGNED_IN'
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED'
export const PASSWORD_CHANGE_FAILED = 'PASSWORD_CHANGE_FAILED'
export const INFLUENCER_SIGN_UP = 'INFLUENCER_SIGN_UP'
export const INFLUENCER_SIGN_UP_FAILED = 'INFLUENCER_SIGN_UP_FAILED'

/* One Time Passcode */
export const REQUEST_ONE_TIME_PASSCODE = 'REQUEST_ONE_TIME_PASSCODE'
export const ONE_TIME_PASSCODE_REQUESTED = 'ONE_TIME_PASSCODE_REQUESTED'
export const ONE_TIME_PASSCODE_REQUEST_FAILED = 'ONE_TIME_PASSCODE_REQUEST_FAILED'
export const VERIFY_ONE_TIME_PASSCODE = 'VERIFY_ONE_TIME_PASSCODE'
export const ONE_TIME_PASSCODE_VERIFIED = 'ONE_TIME_PASSCODE_VERIFIED'
export const ONE_TIME_PASSCODE_VERIFICATION_FAILED = 'ONE_TIME_PASSCODE_VERIFICATION_FAILED'
/* End of One Time Passcode */

/* End of Authentication */
