/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { useState } from 'react'
import axios from 'axios'
import classnames from 'classnames'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'
import useUser from '../../hooks/useUser'
import translate from '../../i18next'
import './index.scss'
import ViewAs from '../VievAs'
import SubmissionForm from '../SubmissionForm'
import ViewAsManager from '../VievAsManager'
import SpinnerCustom from '../common/SpinnerCustom'
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg'
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh-icon.svg'
import { ReactComponent as ButtonArrow } from '../../assets/icons/button-arrow.svg'

const ROOT_CLASS = 'user-ctas'
const copy = require('clipboard-copy')

const UserCTAs = ({ usertype, submissionFormSlug, influencers, influencer, setInfluencer, setSlugVisibility, slugVisibility }) => {
  // console.log('submissionFormSlug:', submissionFormSlug)
  const { userData, setUserData } = useUser()
  const [submissionUrl, setSlug] = useState()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isFormVisible, setFormVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { setError } = useUser()
  const toggleSlugVisibility = () => setSlugVisibility(!slugVisibility)
  const copyText = () => {
    copy(submissionUrl ?? `${window.location.origin}/s/${submissionFormSlug}`)
    toast.success(translate('components.GetStarted.LINK_COPIED'))
  }
  const refreshUrl = () => {
    setLoading(true)
    const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
    axios.defaults.baseURL = API_URL
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios.defaults.headers.Authorization = `bearer ${userData?.token}`
    axios.create().get(
      'influencer/slug/refresh'
    )
      .then(({ data }) => {
        setUserData({ ...userData, submission_form_slug: data.data.submission_form_slug })
        setSlug(`${window.location.origin}/s/${data.data.submission_form_slug}`)
        copy(`${window.location.origin}/s/${data.data.submission_form_slug}`)
        toast.success(translate('components.GetStarted.LINK_REFRESHED'))
      })
      .catch((error) => {
        toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
        if (error.response && error.response.status === 401) {
          setError(error.response)
        }
      })
      .finally(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }
  const isAdmin = usertype === 'super-admin' || usertype === 'admin'
  const IsManager = usertype === 'manager'
  const isInfluncer = usertype === 'influencer'

  return (
    <div className={`${ROOT_CLASS} d-block d-md-flex ${isInfluncer ? 'justify-content-end' : 'justify-content-between'}`}>
      {isLoading && <SpinnerCustom />}
      {isModalOpen && (
      <div className="modal-container">
        <div className="modal-deal">
          <button type="button" className="close" aria-label="Close" onClick={() => setModalOpen(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="text-white text-center mt-5">
            <h3 className="mb-3">Are you sure you want to refresh your link?</h3>
            <strong>This will invalidate your current link</strong>
            <div className="mt-5">
              <button type="button" className="cancel-button" onClick={() => setModalOpen(false)}>Cancel</button>
              <button type="button" className="green-button" onClick={() => refreshUrl(false)}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
      )}
      {isFormVisible && (
      <div className="modal-container">
        <div className="modal-deal">
          <button type="button" className="close" aria-label="Close" onClick={() => setFormVisible(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
          <SubmissionForm isModal isEditView submissionFormSlug={submissionFormSlug} />
        </div>
      </div>
      )}
      {false && (
        <ViewAs influencers={influencers} influencer={influencer} setInfluencer={setInfluencer} />
      )}
      {(IsManager || isAdmin) && (
        <ViewAsManager influencers={influencers} influencer={influencer} setInfluencer={setInfluencer} isAdmin={isAdmin} />
      )}
      <div className="buttons-container col-12 col-md-6 d-block d-md-flex justify-content-end">
        {!isAdmin
          && (
            <>
              <Button
                className="purple-button d-none d-lg-block"
                onClick={() => setFormVisible(!isFormVisible)}
              >
                View Form
              </Button>
              <div>
                <Button
                  className="green-button"
                  onClick={() => toggleSlugVisibility()}
                >
                  My Page Link <ButtonArrow />
                </Button>
                <div
                  className={classnames('form-link position-absolute p-2', { 'd-none': !slugVisibility, 'd-block': slugVisibility })}
                >
                  <div>
                    <span>
                      {`${submissionUrl ?? `${window.location.origin}/s/${submissionFormSlug}`}`}
                    </span>
                    <span className="copy-icon" onClick={copyText}>
                      <CopyIcon />
                    </span> <span className="refresh-icon" onClick={() => setModalOpen(true)}><RefreshIcon /></span>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  )
}

export default UserCTAs
