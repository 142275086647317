import './EmailForm.scss'

import * as Yup from 'yup'

import PropTypes from 'prop-types'

import classnames from 'classnames'
import { Formik, ErrorMessage } from 'formik'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import translate from '../../i18next'

const ROOT_CLASS = 'forgot-password-email-form'
const FORGOT_FORM_CLASS = `${ROOT_CLASS}-form`
const FORGOT_FORM_SUBMIT_SECTION_CLASS = `${ROOT_CLASS}-form-submit-container`
const FORM_INPUT_ERROR_MESSAGE_CLASS = `${FORGOT_FORM_CLASS}-input-error-message`
const FORM_INPUT_ERROR_CLASS = `${FORGOT_FORM_CLASS}-input-error`
const FORGOT_BUTTON_CLASS = `${FORGOT_FORM_SUBMIT_SECTION_CLASS}-submit-button`

const INITIAL_FORM_VALUES = {
  email: ''
}

const FORM_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email') // TODO, get translate from designer
    .required('Required')
})

const EmailForm = ({ onSubmit, onJumpToOTP, isLoading }) => {
  return (
    <Container className={ROOT_CLASS}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={FORM_SCHEMA}
        initialValues={INITIAL_FORM_VALUES}
      >
        {({ values, errors, touched, isValid, handleChange, handleBlur, handleSubmit }) => (
          <Form className={FORGOT_FORM_CLASS} onSubmit={handleSubmit}>
            <Container>
              <Form.Group as={Row}>
                <Form.Label>{translate('components.ForgotPassword.EMAIL_LABEL')}</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  placeholder={translate('components.ForgotPassword.EMAIL_INPUT_PLACEHOLDER')}
                  className={
                    classnames({ [FORM_INPUT_ERROR_CLASS]: errors.email && touched.email })
                  }
                  disabled={isLoading}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage name="email" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
              </Form.Group>
            </Container>
            <Container className={FORGOT_FORM_SUBMIT_SECTION_CLASS}>
              <Button
                type="submit"
                disabled={isLoading || !isValid}
                className={FORGOT_BUTTON_CLASS}
              >
                {translate('components.ForgotPassword.FORGOT_BUTTON_CAPTION')}
              </Button>
              <Button
                variant="link"
                onClick={onJumpToOTP}
              >
                {translate('components.ForgotPassword.ALREADY_HAVE_2FA_LABEL_CAPTION')}
              </Button>
            </Container>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

EmailForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onJumpToOTP: PropTypes.func.isRequired
}

EmailForm.defaultProps = {
  isLoading: false
}

export default EmailForm
