import './DeactivateAccount.scss'

import { useState } from 'react'
import PropTypes from 'prop-types'
import translate from '../../i18next'
import InputFilter from '../InputFilter'

const ROOT_CLASS = 'deactivate-account'
const FOOTER_CLASS = `${ROOT_CLASS}-footer`
const BODY_CLASS = `${ROOT_CLASS}-body`
const TITLE_CLASS = `${ROOT_CLASS}-title`
const SUBTITLE_CLASS = `${ROOT_CLASS}-subtitle`
const TEXT_CLASS = `${ROOT_CLASS}-text`
const CANCEL_BUTTON_CLASS = `${ROOT_CLASS}-cancel-button`
const CONFIRM_BUTTON_CLASS = `${ROOT_CLASS}-confirm-button`

const DeactivateAccount = ({ setActionOk, setShow }) => {
  const [disabledSubmit, setDisabledSubmit] = useState(true)

  const onChangeInput = (text) => {
    if (text.toUpperCase() === 'DEACTIVATE') setDisabledSubmit(false); else setDisabledSubmit(true)
  }

  return (
    <div className={ROOT_CLASS}>
      <div className={BODY_CLASS}>
        <div className={TITLE_CLASS}>{translate('components.AccountSettings.DELETE_PROFILE_TEXT_1')}</div>
        <div className={SUBTITLE_CLASS}>{translate('components.AccountSettings.DELETE_PROFILE_TEXT_2')}</div>
        <div className={TEXT_CLASS}>{translate('components.AccountSettings.DELETE_PROFILE_TEXT_CONFIRMATION')}</div>
        <InputFilter onChange={onChangeInput} placeholder="" name="deactivate" />
      </div>
      <div className={`${FOOTER_CLASS} text-center`}>
        <button type="button" className={CANCEL_BUTTON_CLASS} onClick={() => setShow(false)}>
          {translate('components.Common.CANCEL')}
        </button>
        <button type="button" disabled={disabledSubmit} className={CONFIRM_BUTTON_CLASS} onClick={() => { setActionOk(); setShow(false) }}>
          {translate('components.AccountSettings.DELETE_PROFILE_CONFIRM_BUTTON')}
        </button>
      </div>
    </div>
  )
}

DeactivateAccount.propTypes = {
  setActionOk: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired
}

export default DeactivateAccount
