import { useCallback } from 'react'

// import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import SignIn from '../SignIn'
import useUser from '../../hooks/useUser'

// import { isUserLoggedInSelector } from '../../store/selectors/userSelector'

// const PrivateRoute = ({ isUserLoggedIn, path, exact, component }) => {
export default function PrivateRoute({ path, exact, component }) {
  const { isLogged } = useUser()

  const renderComponent = useCallback(() => {
    return isLogged ? component : SignIn
  }, [isLogged])

  return (
    <Route
      path={path}
      exact={exact}
      component={renderComponent()}
    />
  )
}

PrivateRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  // component: PropTypes.node.isRequired,
  component: PropTypes.elementType.isRequired,
  // isUserLoggedIn: PropTypes.bool.isRequired
}

// export const mapStateToProps = (stateProps, ownProps) => {
//   // console.log('stateProps', stateProps)
//   const isUserLoggedIn = isUserLoggedInSelector(stateProps)
//   return {
//     isUserLoggedIn,
//     ...ownProps
//   }
// }

// export const mapDispatchToProps = {}

// const mergeProps = (stateProps, _, ownProps) => {
//   return {
//     ...stateProps,
//     ...ownProps
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PrivateRoute)
