import { createSelector } from 'reselect'
import { USER_STORAGE_KEY } from '../actions/user.types'
import storage from '../../helpers/storage'

const userReducer = (state) => state.userReducer

export const isLoadingSelector = createSelector(
  userReducer,
  (items) => items.isLoading
)

export const isUserLoggedInSelector = createSelector(
  userReducer,
  () => !!storage.get(USER_STORAGE_KEY)
  // (item) => {
  //   console.log('item.data', item.data)
  //   console.log('item.data.token', item.data ? item.data.token : false)
  //   console.log('return', !!(item.data && item.data.token))
  //   return !!(item.data && item.data.token)
  // }
)
