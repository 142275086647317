/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './index.scss'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useUser from '../../hooks/useUser'
import translate from '../../i18next'
import Stats from '../Stats'
import Submissions from '../Submissions'
import withSideBarMenu from '../SideBarMenu/withSideBarMenu'
import UserCTAs from '../UserCTAs'
import SpinnerCustom from '../common/SpinnerCustom'
import { Footer } from '../Landing/Footer/Footer'
import { getSubmissionList } from '../../api/submission'
import { getUsers } from '../../api/admin'
import { getInfluencers } from '../../api/manager'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ROOT_CLASS = 'dealme-home'

const Home = () => {
  const query = useQuery()
  const { setError, userData } = useUser()
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('name') // company, inquiry_type, amount, name, email, network, handle, timestamp
  const [orderDirection, setOrderDirection] = useState('ASC') // ASC, DESC
  const [totalRows, setTotalRows] = useState(0)
  const [submissions, setSubmissions] = useState([])
  const [influencers, setInfluencers] = useState([])
  const [influencer, setInfluencer] = useState()
  const [modalContent, setModalContent] = useState(-1)
  const [isLoading, setLoading] = useState(false)
  const [slugVisibility, setSlugVisibility] = useState(false)
  const isAdmin = userData?.user_type === 'super-admin' || userData?.user_type === 'admin'
  const IsManager = userData?.user_type === 'manager'

  const getSubmissions = (slug, id) => {
    // console.log('get Submissions slug', slug, id)
    setLoading(true)
    getSubmissionList(slug)
      .then(({ data }) => {
        setSubmissions(data.submissions ?? [])
        setTotalRows(data.totalRows ?? 0)
        if (id) {
          setModalContent(parseInt(id, 10))
          setInfluencer(influencers.find((influencer) => influencer.submission_form_slug === slug))
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setError(error.response)
        }
        setSubmissions([])
        setTotalRows(0)
      })
      .finally(() => { setLoading(false) })
  }

  useEffect(() => {
    // console.log('useEffect', (`${influencer?.submission_form_slug ?? userData?.submission_form_slug ?? ''}?ps=${itemsPerPage}&p=${page}&o=${orderBy}&od=${orderDirection}`))
    if (itemsPerPage && page && (influencer || isAdmin || userData?.submission_form_slug) && orderBy && orderDirection) {
      // getSubmissions(influencer?.submission_form_slug ?? userData?.submission_form_slug ?? '')
      getSubmissions(
        `${influencer?.submission_form_slug ?? userData?.submission_form_slug ?? ''}?pageSize=${itemsPerPage}&page=${page}&orderBy=${orderBy}&orderDirection=${orderDirection}`
      )
    }
  }, [itemsPerPage, page, influencer, orderBy, orderDirection])

  useEffect(() => {
    // console.log('useEffect []')
    const slug = query.get('slug')
    const id = query.get('id')
    if (slug || id) {
      if (IsManager || isAdmin) {
        getSubmissions(slug || '', id)
      } else if (slug === userData?.submission_form_slug) {
        setModalContent(parseInt(id, 10))
      }
    } else if (isAdmin) {
      setLoading(true)
      Promise.all([
        getUsers(),
        // getSubmissionList(`?pageSize=${itemsPerPage}&page=${page}&orderBy=${orderBy}&orderDirection=${orderDirection}`)
      ])
        .then((res) => {
          setInfluencers(res[0].data.filter((user) => user.user_type === 'influencer'))
          // setSubmissions(res[1].data.submissions ?? [])
          // setTotalRows(res[1].data.totalRows ?? 0)
        })
        .catch((error) => {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          if (error.response && error.response.status === 401) {
            setError(error.response)
          }
        })
        .finally(() => { setLoading(false) })
    } else if (IsManager) {
      setLoading(true)
      getInfluencers()
        .then(({ data }) => {
          if (data && data.length > 0) {
            // console.log('getInfluencers getSubmissions', data[0]?.submission_form_slug)
            setInfluencers(data)
            setInfluencer(data[0])
            // getSubmissions(data[0]?.submission_form_slug ?? '') // will be loaded when influencer is set
          }
        })
        .catch((error) => {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          if (error.response && error.response.status === 401) {
            setError(error.response)
          }
        })
        .finally(() => { setLoading(false) })
    } else {
      // getSubmissions(
      //   `${userData?.submission_form_slug ?? ''}?pageSize=${itemsPerPage}&page=${page}&orderBy=${orderBy}&orderDirection=${orderDirection}`
      // )
    }
  }, [])

  // useEffect(() => {
  //   // console.log('useEffect influencer', (influencer?.submission_form_slug ?? userData?.submission_form_slug ?? ''))
  //   getSubmissions(influencer?.submission_form_slug ?? userData?.submission_form_slug ?? '')
  // }, [influencer])

  const openMessages = submissions.filter((message) => message.readed).length
  const closedMessages = submissions.filter((message) => !message.readed).length
  const stats = [{
    title: 'Unopened',
    value: closedMessages
  },
  {
    title: 'Opened',
    value: openMessages
  }]

  // useEffect(() => {
  //   console.log('useEffect slug')
  //   const slug = query.get('slug')
  //   const id = query.get('id')
  //   if (IsManager || isAdmin) {
  //     getSubmissions(slug, id)
  //   } else if (slug === userData?.submission_form_slug) {
  //     setModalContent(parseInt(id, 10))
  //   }
  // },
  // [])

  return (
    <div
      className={ROOT_CLASS}
      onClick={
        () => {
          if (slugVisibility) { setSlugVisibility(false) }
        }
      }
    >
      {isLoading && <SpinnerCustom />}
      <UserCTAs
        usertype={userData?.user_type}
        submissionFormSlug={influencer?.submission_form_slug ?? userData?.submission_form_slug}
        influencers={influencers}
        influencer={influencer}
        setInfluencer={setInfluencer}
        slugVisibility={slugVisibility}
        setSlugVisibility={setSlugVisibility}
      />
      <Stats stats={stats} />
      <Submissions
        submissions={submissions}
        submissionsPerPage={itemsPerPage}
        setSubmissionsPerPage={setItemsPerPage}
        page={page}
        setPage={setPage}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        totalRows={totalRows}
        setSubmissions={setSubmissions}
        submissionFormSlug={influencer?.submission_form_slug ?? userData?.submission_form_slug}
        modalContent={modalContent}
        setModalContent={setModalContent}
        initialModal={query.get('slug') && query.get('id')}
        loading={isLoading}
        setLoading={setLoading}
      />
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

export default withSideBarMenu({ defaultCollapsed: false })(Home)
