export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const USER_SIGN_IN = 'auth/login'
export const REFRESH_TOKEN = '/auth/refresh'
export const INFLUENCER_SIGN_UP = 'influencer/register'
export const INFLUENCER_INVITATION = '/influencer/invitation'
export const INVITATION = '/invitation'
export const MANAGER_INVITATION = '/manager/invitation'
export const MANAGER_INVITATION_ACCEPT = '/manager/invitation/accept'
export const ADMIN_INVITATION = '/admin/invite'
export const ADMIN_INVITATION_ACCEPT = '/admin/invite/accept'
export const ADMIN_LIST_USERS = '/admin/user'
export const EMAIL_VERIFICATION = 'user/email/verification'
export const EMAIL_PASSWORD_RECOVERY = 'user/email/password/recovery'
export const EMAIL_PASSWORD_RECOVERY_VERIFICATION = 'user/email/password/recovery/code-validation'
export const EMAIL_PASSWORD_RECOVERY_CHANGE = 'user/email/password'
export const USER_IMAGE_UPLOAD = '/user/image/upload'
export const USER_UPDATE = '/user'
export const USER_DELETE = '/user'
export const USER_NOTIFICATION = '/user/notification'
export const USER_CHANGES_VERIFICATION = '/user/changes/verification'
