/* eslint-disable react/prop-types */
import StatBox from '../StatBox'

const Stats = ({ stats }) => {
  return (
    <>
      <h2 className="p-2">Stats</h2>
      <div className="row">
        {stats.map(({ title, value }) => (
          <StatBox title={title} value={value} />
        ))}
      </div>
    </>
  )
}

export default Stats
