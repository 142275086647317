import storage from '../../helpers/storage'
import {
  ACCESS_TOKEN_KEY,
  READ_ACCESS_TOKEN,
  READ_ACCESS_TOKEN_FAIL,
  // SET_ACCESS_TOKEN,
} from '../actions/accessToken'

const DEFAULT_STATE = {
  accessToken: storage.get(ACCESS_TOKEN_KEY),
}

export default (state = DEFAULT_STATE, action = {}) => {
  const { accessToken } = action

  switch (action.type) {
    case READ_ACCESS_TOKEN:
      return { ...state, accessToken }
    case READ_ACCESS_TOKEN_FAIL:
      return { ...state, accessToken: null }
    // case SET_ACCESS_TOKEN:
    //   console.log('accessToken reducer: action', action)
    //   return { ...state, accessToken: action.payload }
    default:
      return state
  }
}
