import { apiCall } from './api_client'
import { USER_NOTIFICATION } from './auth.routes'

export const getNotification = () => apiCall(USER_NOTIFICATION, { method: 'GET', immediately: true })

export const sendNotification = (data) => apiCall(USER_NOTIFICATION, {
  method: 'POST',
  immediately: true,
  data,
})
