/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import './Landing.scss'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import { Header } from './Header'
import { Story } from './Story/Story'
import { Footer } from './Footer/Footer'

const stories = [
  {
    name: 'John Lennon',
    role: 'Influencer',
    image: './cover.jpg',
    text: `Aenean non turpis vitae libero scelerisque volutpat.
    Suspendisse lacinia porta ligula efficitur vehicula. Quisque eget orci dolor.
    Aliquam erat volutpat. Pellentesque volutpat nunc et nulla pulvinar convallis.
    Sed venenatis ligula eu suscipit condimentum.`
  },
  {
    name: 'Emery George',
    role: 'Manager',
    image: './girl.png',
    text: ` Pellentesque volutpat nunc et nulla pulvinar convallis.
    Sed venenatis ligula eu suscipit condimentum.
    Aenean non turpis vitae libero scelerisque volutpat.
    Suspendisse lacinia porta ligula efficitur vehicula. Quisque eget orci dolor.
    Aliquam erat volutpat.`
  },
  {
    name: 'Yoko Ono',
    role: 'Influencer',
    image: './cover.jpg',
    text: `Aenean non turpis vitae libero scelerisque volutpat.
    Suspendisse lacinia porta ligula efficitur vehicula. Quisque eget orci dolor.
    Aliquam erat volutpat. Pellentesque volutpat nunc et nulla pulvinar convallis.
    Sed venenatis ligula eu suscipit condimentum.`
  }
]

const ROOT_CLASS = 'landing'
const SECTION_ONE_CONTAINER_CLASS = `${ROOT_CLASS}-section-one-container`
const SECTION_ONE_LEFT_CONTAINER_CLASS = `${ROOT_CLASS}-section-one-left-container`
const SECTION_ONE_RIGHT_CONTAINER_CLASS = `${ROOT_CLASS}-section-one-right-container`

const SECTION_FOUR_CONTAINER_CLASS = `${ROOT_CLASS}-section-four-container`

export const Landing = () => {
  const history = useHistory()

  const [storySelected, setStorySelect] = useState(0)

  const changeSelect = (e) => {
    setStorySelect(parseInt(e.target.id, 10))
  }

  const handleSignupButtonClick = useCallback(() => {
    history.push('/signup')
  }, [history])

  const handlers = useSwipeable({
    onSwipedLeft: () => setStorySelect(storySelected < 2 ? storySelected + 1 : storySelected),
    onSwipedRight: () => setStorySelect(storySelected > 0 ? storySelected - 1 : storySelected),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
    <div className={ROOT_CLASS}>
      <Header />
      <Row>
        <div className={SECTION_ONE_CONTAINER_CLASS}>
          <Col sm={6}>
            <Container className={SECTION_ONE_LEFT_CONTAINER_CLASS}>
              <h1>Take control of your social media deal flow.</h1>
              <h4>Success as a creator often leads to problems being organized. Deal.me helps you better manage collabs and branded content reachouts to ensure you are responding to the right opportunities.
              </h4>
              <Button onClick={handleSignupButtonClick}>Signup Free</Button>
            </Container>
          </Col>
          <Col sm={6}>
            <Container className={SECTION_ONE_RIGHT_CONTAINER_CLASS} />
          </Col>
        </div>
      </Row>
      <div className="how">
        <h2>How it Works</h2>
        <img src="/social-circle.png" alt="Social medias" className="social-circle" />
        <div className="how-container">
          <div className="number-cicle one">1</div>
          <h3>Add your custom Deal.me link to all of your social media profiles.</h3>
          <h4>
            Don’t leave any deal on the table. Become more discoverable and accessible by linking your Deal.me page to your social media accounts and discover which brands are interested in partnering with you.
          </h4>
        </div>
      </div>
      <div className="brand">
        <div className="brand-container">
          <img src="/influenshe.png" alt="Influenshe" className="influenshe d-block d-lg-none" />
          <div className="number-cicle two">2</div>
          <h3>Brands and other creators submit requests through a personalized page</h3>
          <h4>
            Manage all inbound opportunities in your profile. Learn more about the brand campaign and determine if it’s the right fit for you. Engage with the brand right there on the spot.
          </h4>
        </div>
        <img src="/influenshe.png" alt="Influenshe" className="influenshe d-none d-lg-inline" />
      </div>
      <div className="instantly">
        <img src="/phone.png" alt="Instantly" className="phone" />
        <div className="instantly-container">
          <div className="number-cicle three">3</div>
          <h3>Instantly get notified when offers come through. Have a manager? Add them too</h3>
          <h4>
            Keep your whole team in the know. Filter and keep track of brand opportunities all in one place and never lose track of your earning potential.
          </h4>
        </div>
      </div>
      <div className="dont">
        <h2 className="mx-auto">Don’t miss out on brand deals to your inbox. Centralize everything with Deal.me</h2>
        <div className="d-lg-flex justify-content-around">
          <div className="dont-item">
            <img src="/personalized.png" alt="personalized" />
            <h3>Personalized</h3>
            <h4>Customize your profile with more detailed information including your image, bio, current brand partners, representatives and more.</h4>
          </div>
          <div className="dont-item">
            <img src="/eye.png" alt="personalized" />
            <h3>Transparent</h3>
            <h4>Get an overview of your progress, communication, and where you stand in the marketplace in real time.</h4>
          </div>
          <div className="dont-item">
            <img src="/collaborative.png" alt="personalized" />
            <h3>Collaborative</h3>
            <h4>Take ownership of your brand. Communicate with the brand directly from your profile to align campaign messaging and direction to fit you.</h4>
          </div>
        </div>
        <div className="d-lg-flex justify-content-even px-lg-5 mx-lg-5">
          <div className="dont-item le">
            <img src="/efficient.png" alt="personalized" />
            <h3>Efficient</h3>
            <h4>Monitor past, present, and future opportunities all in one spot. From accepting a deal to getting paid out, you’ll never have to lose track of where you stand during each campaign.</h4>
          </div>
          <div className="dont-item ri">
            <img src="/professional.png" alt="personalized" />
            <h3>Professional</h3>
            <h4>Brands are only reaching out to you with serious inquiries only. Have sincere conversations about real opportunities with brand execs.</h4>
          </div>

        </div>
      </div>
      <Row className="story-row">
        <Container
          className={SECTION_FOUR_CONTAINER_CLASS}
          {...handlers}
        >
          <Story stories={stories[0]} style={{ left: `${-parseInt(storySelected, 10) * 100}vw` }} />
          <Story stories={stories[1]} style={{ left: `${100 - parseInt(storySelected, 10) * 100}vw` }} />
          <Story stories={stories[2]} style={{ left: `${200 - parseInt(storySelected, 10) * 100}vw` }} />
          <div className="selectors">
            <div>
              <input type="radio" id="0" name="storySelect" onClick={changeSelect} checked={storySelected === 0} />
            </div>
            <div>
              <input type="radio" id="1" name="storySelect" onClick={changeSelect} checked={storySelected === 1} />
            </div>
            <div>
              <input type="radio" id="2" name="storySelect" onClick={changeSelect} checked={storySelected === 2} />
            </div>
          </div>
        </Container>
      </Row>
      <div className="sign-up-container px-2">
        <h2>Get your Deal.me account</h2>
        <h2>set up in minutes</h2>
        <div className="d-flex align-items-center">
          <Button onClick={handleSignupButtonClick}>Signup Free</Button>
        </div>
      </div>
      <Footer />
    </div>
  )
}

// Use default export for connect / redux if needed.
