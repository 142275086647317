import './NewPasswordForm.scss'

import { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as Yup from 'yup'

import { Formik, ErrorMessage } from 'formik'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import translate from '../../i18next'

import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg'

const ROOT_CLASS = 'forgot-password-new-password-form'
const NEW_PASSWORD_FORM_CLASS = `${ROOT_CLASS}-form`
const NEW_PASSWORD_FORM_SUBMIT_SECTION_CLASS = `${ROOT_CLASS}-form-submit-container`
const FORM_INPUT_ERROR_MESSAGE_CLASS = `${NEW_PASSWORD_FORM_CLASS}-input-error-message`
const FORM_INPUT_ERROR_CLASS = `${NEW_PASSWORD_FORM_CLASS}-input-error`
const SIGNIN_BUTTON_CLASS = `${NEW_PASSWORD_FORM_SUBMIT_SECTION_CLASS}-submit-button`

const INITIAL_FORM_VALUES = {
  password: '',
  confirmPassword: '',
  email: ''
}

const FORM_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .required('Required'), // TODO: get translate from designer
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match') // TODO: get translate from designer
})

const FORM_SCHEMA_EMAIL = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email') // TODO, get translate from designer
    .required('Required'),
  password: Yup.string()
    .required('Required'), // TODO: get translate from designer
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match') // TODO: get translate from designer
})

const NewPasswordForm = ({ onPasswordChanged, editEmail }) => {
  const [isLoading] = useState(false)
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const handlePasswordVisibleIconClick = useCallback(() => {
    setPasswordVisible(!isPasswordVisible)
  }, [isPasswordVisible])

  const passwordFieldType = useMemo(() => {
    return isPasswordVisible ? 'text' : 'password'
  }, [isPasswordVisible])

  const renderPasswordVisibilityIcon = useCallback(() => {
    return isPasswordVisible ? (
      <EyeSlashIcon onClick={handlePasswordVisibleIconClick} />
    ) : (
      <EyeIcon onClick={handlePasswordVisibleIconClick} />
    )
  }, [isPasswordVisible, handlePasswordVisibleIconClick])

  const handleOnFormSubmit = useCallback((values) => {
    onPasswordChanged(values)
  }, [onPasswordChanged])

  return (
    <Container className={ROOT_CLASS}>
      <Formik
        onSubmit={handleOnFormSubmit}
        validationSchema={editEmail ? FORM_SCHEMA_EMAIL : FORM_SCHEMA}
        initialValues={INITIAL_FORM_VALUES}
      >
        {({ values, errors, touched, isValid, handleChange, handleBlur, handleSubmit }) => (
          <Form noValidate className={NEW_PASSWORD_FORM_CLASS} onSubmit={handleSubmit}>
            <Container>
              {editEmail
              && (
              <Form.Group as={Row}>
                <Form.Label>{translate('components.ForgotPassword.EMAIL_LABEL')}</Form.Label>
                <Form.Control
                  required
                  autoFocus
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  placeholder={translate('components.ForgotPassword.EMAIL_INPUT_PLACEHOLDER')}
                  className={
                    classnames({ [FORM_INPUT_ERROR_CLASS]: errors.email && touched.email })
                  }
                  disabled={isLoading}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage name="email" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
              </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label>{translate('components.NewPasswordForm.NEW_PASSWORD_LABEL')}</Form.Label>
                <InputGroup>
                  <FormControl
                    required
                    autoFocus={!editEmail}
                    name="password"
                    type={passwordFieldType}
                    value={values.password}
                    placeholder={translate('components.NewPasswordForm.NEW_PASSWORD_INPUT_PLACEHOLDER')}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.password && touched.password })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <InputGroup.Append> {renderPasswordVisibilityIcon()} </InputGroup.Append>
                </InputGroup>
                <ErrorMessage name="password" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label>{translate('components.NewPasswordForm.REPEAT_PASSWORD_LABEL')}</Form.Label>
                <InputGroup>
                  <FormControl
                    required
                    name="confirmPassword"
                    type={passwordFieldType}
                    value={values.confirmPassword}
                    placeholder={translate('components.NewPasswordForm.REPEAT_PASSWORD_INPUT_PLACEHOLDER')}
                    className={
                      classnames({ [FORM_INPUT_ERROR_CLASS]: errors.confirmPassword && touched.confirmPassword })
                    }
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <InputGroup.Append> {renderPasswordVisibilityIcon()} </InputGroup.Append>
                </InputGroup>
                <ErrorMessage name="confirmPassword" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
              </Form.Group>
            </Container>
            <Container className={NEW_PASSWORD_FORM_SUBMIT_SECTION_CLASS}>
              <Button
                type="submit"
                disabled={isLoading || !isValid}
                className={SIGNIN_BUTTON_CLASS}
              >
                {translate(editEmail ? 'components.Common.RESET' : 'components.NewPasswordForm.RESET_PASSWORD_BUTTON_CAPTION')}
              </Button>
            </Container>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

NewPasswordForm.propTypes = {
  onPasswordChanged: PropTypes.func.isRequired,
  editEmail: PropTypes.bool
}

NewPasswordForm.defaultProps = {
  editEmail: false
}

export default NewPasswordForm
