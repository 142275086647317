import './index.scss'

import PropTypes from 'prop-types'

const ROOT_CLASS = 'dealme-stepper-dots'

export default function StepperDots(props) {
  const { stepsNumber, onSelect, activeStep } = props

  return (
    <div className={ROOT_CLASS}>
      {[...Array(stepsNumber)].map((x, i) => (
        <input
          // eslint-disable-next-line
          key={i + 1}
          index={i + 1}
          type="radio"
          onClick={() => { if (activeStep !== i) onSelect(i) }}
          checked={activeStep === i}
          aria-label="Dot"
        />
      ))}
    </div>
  )
}

StepperDots.propTypes = {
  onSelect: PropTypes.func.isRequired,
  stepsNumber: PropTypes.number,
  activeStep: PropTypes.number.isRequired
}

StepperDots.defaultProps = {
  stepsNumber: 3
}
